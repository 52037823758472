import React, {Component} from 'react';
import {Card, notification, Modal, Button, Input, Switch} from 'antd';
import SelectTable from "./SelectTable";
import FilterForm from "../../../components/Filter2";
import HttpUtils from "../../../utils/HttpUtils";
import './UserSelect.scss'
const select = [
    {
        name: '手机号/姓名',
        type: 'input',
        dataIndex: 'key',
        inputWidth:300,
        seachLeft:20
    },
];
export default class RoomExamineList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 999,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: '',
            data: undefined,
            table_loading: false,
            count: 0,
            selectedRowKeys: [],
            start_hour:this.props.timeObj,
            visible2:false

        }

        this.columns = [
            {
                title: '微信头像',
                dataIndex: 'avatar',
                key: 'avatar',
                width: 100,
                render:(text => (
                    <img src={text} style={{maxHeight:60,maxWidth:60}}  />
                ))
            },
            {
                title: '微信昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                width: 100,
            },
            {
                title: '手机号',
                dataIndex: 'mobile_phone',
                key: 'mobile_phone',
                width: 150,
            },
            {
                title: '姓名(人员姓名)',
                dataIndex: 'name',
                key: 'name',
                width: 120,
                render:((text,record)=>{
                    if(record.last_name&&record.last_name.length>0){
                        return `${text}(${record.last_name})`
                    }else {}
                    return  text
                })
            },
            {
                title: '性别',
                dataIndex: 'gender',
                key: 'gender',
                width: 100,
                render:(text=>{
                    switch (text) {
                        case '' : return '未知'
                        case 'M' : return '男'
                        case 'F' : return '女'
                    }
                })
            },
            {
                title: '是否管理员',
                dataIndex: 'is_checker',
                width: 100,
                key: 'is_checker',
                render: (text) => text === 1?'是':'不是'
            },
            {
                title: '所在城市',
                dataIndex: 'city',
                key: 'city',
                width: 100,
            },
            {
                title: '开始时长',
                width: 250,
                render:(text,record,index)=>(
                    <div className={'start_time'}>
                        <Input  defaultValue={record.start_day} onChange={
                            (e)=>{record.start_day=e.target.value}

                        } addonAfter={'天'} autoComplete="off"/>
                        <Input  defaultValue={record.start_hour} onChange={
                            (e)=>{record.start_hour=e.target.value}

                        } addonAfter={'时'} autoComplete="off"/>
                        <Input  defaultValue={record.start_minute} onChange={
                            (e)=>{record.start_minute=e.target.value}

                        } addonAfter={'分'} autoComplete="off"/>
                    </div>
                )

            },
            {
                title: '结束时长',
                width: 250,
                render:(text,record)=>(
                    <div className={'start_time'}>
                        <Input  defaultValue={record.end_day} onChange={
                            (e)=>{record.end_day=e.target.value}

                        } addonAfter={'天'} autoComplete="off"/>
                        <Input  defaultValue={record.end_hour} onChange={
                            (e)=>{record.end_hour=e.target.value}

                        } addonAfter={'时'} autoComplete="off"/>
                        <Input  defaultValue={record.end_minute} onChange={
                            (e)=>{record.end_minute=e.target.value}

                        } addonAfter={'分'} autoComplete="off"/>
                    </div>
                )
            },
        ]

    }




    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }





    timeStamp( second_time ){

        var time = parseInt(second_time) + "秒";
        if( parseInt(second_time )> 60){

            var min = parseInt(second_time / 60);
            time = min + "分" ;

            if( min > 60 ){
                min = parseInt(second_time / 60) % 60;
                var hour = parseInt( parseInt(second_time / 60) /60 );
                if(min === 0){
                    time = hour + "小时" ;
                }else {
                    time = hour + "小时" + min + "分" ;
                }

                if( hour > 24 ){
                    hour = parseInt( parseInt(second_time / 60) /60 ) % 24;
                    var day = parseInt( parseInt( parseInt(second_time / 60) /60 ) / 24 );
                    if(min ===0 ){
                        time = day + "天" + hour + "小时" ;
                    }else {
                        time = day + "天" + hour + "小时" + min + "分" ;
                    }
                }
            }


        }

        return time;
    }

    componentDidMount() {
    }

    _show() {
        this.setState({visible: true, loading: true,visible2:false})
        this.getData();
        this.props.onClick()
    }


    getData() {
        this.setState({table_loading: true})
        HttpUtils.postForm('/api/room/examine/distribution/list', {
            ...this.state.form,
            ...this.state.values,
        }).then(res => {
            this.setState({table_loading: false})
            if (res.status === 10000) {
                this.setState({
                    dataSource: res.data,
                    count: res.count
                })
            }
        }).catch(err => {
            window.$message.error('通讯失败')
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }



    openNotificationWithIcon = (type, successData, failureData) => {
        console.log(type, successData, failureData)
        notification[type]({
            duration: null,
            message: '操作结果',
            description: <>
                {successData && <div style={{marginBottom: 10}}>
                    <span style={{color: '#87d068', fontSize: 18}}>成功内容</span>
                    {successData.map(item => (
                        <div>
                            <div>用户名:{item.user.name}</div>
                        </div>
                    ))}
                </div>}
                {
                    failureData && <div>
                        <span style={{color: '#f50', fontSize: 18}}>失败内容</span>
                        {failureData.map(item => (
                            <div style={{marginBottom: 10}}>
                                <div>用户名:{item.user.name}</div>
                                <div>原因:{item.reason}</div>
                            </div>
                        ))}
                    </div>
                }
            </>

        });
    };

    checkConfirm=(id)=>{
        const Data = this.custom_table.state.dataSource.filter(item=>
            id.filter(e=>e === item.id).length > 0
        )

        const newData = Data.map(term=>({
            user_id:term.id,
            start_space_time:Number(Number(term.start_day * 60 * 24)  + Number(term.start_hour * 60) + Number(term.start_minute)) * 60 || 0,
            end_space_time:Number(Number(term.end_day * 60 * 24)  + Number(term.end_hour * 60) + Number(term.end_minute)) * 60 || 7200
        }))


        HttpUtils.postForm('/api/batch/examine/distribution/user', {
            data:JSON.stringify({
                room_id:this.props.room_id,
                user_distributions:newData //数组
            })
        }).then(res => {
            if (res.status === 10000) {
                this.setState({visible:false})
                if (res.data.examine_failure_data === null) {
                    this.openNotificationWithIcon('success', res.data.examine_success_data) //全部成功

                } else if (res.data.examine_success_data === null) {
                    this.openNotificationWithIcon('warning', res.data.examine_success_data,res.data.examine_failure_data) //全部失败

                } else {
                    this.openNotificationWithIcon('warning', res.data.examine_success_data, res.data.examine_failure_data) //有失败，有成功
                }

            } else {
                window.$message.error(res.message);
            }

        }).catch(err => {
            window.$message.error('通讯失败');
        })

    }

    render() {
        return (
            <div>
                <Button type="primary" style={{marginRight: 0}} onClick={()=>{this._show()}}>选择用户</Button>
                <Modal
                    title={'用户选择'}
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    width={window.innerWidth * 0.8}
                    onCancel={this.handleCancel}
                    style={{position: 'relative', top: '5%', left: 0, right: 0}}
                >
                    <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                    <Card bordered={false}>
                        <SelectTable
                            scroll={{y:(document.querySelector('body').offsetHeight * 0.55)}}
                            title={'提交'}
                            checked_box
                            checkConfirm={(id) => {
                                this.checkConfirm(id)
                            }}
                            timeObj={this.props.timeObj}
                            value={{room_id:this.props.room_id,is_checker:1}}
                            url={'/api/user/list'}
                            columns={this.columns}
                            ref={(e) => {
                                this.custom_table = e
                            }}
                        />
                    </Card>
                </Modal>
            </div>
        );
    }
}



