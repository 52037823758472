import React, {Component} from 'react';
import {Table, Input, Button} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";
import PropTypes from 'prop-types';
import ConfirmPop from "../../../pages/Room/components/ConfirmPop";


export default class SelectTable extends Component {

    static defaultProps = {
        pagination: true,
        scroll: undefined,
        checked_box: false
    }

    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            width: PropTypes.number,
            title: PropTypes.string,
            dataIndex: PropTypes.string,
            key: PropTypes.string,
            render: PropTypes.func
        })).isRequired,
        url: PropTypes.string.isRequired,
        scroll: PropTypes.object,
        checked_box: PropTypes.bool,
        checkConfirm: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            form: {
                pers: 1000,
                page: 1,
            },
            values: {},
            visible: false,
            selectedRowKeys: []

        };
    }

    componentDidMount() {
        this.getList()
    }

    onSubmit = (value) => {
        this.setState({
            form: {
                page: 1,
                pers: this.state.form.pers,
            },
            values: value
        }, () => {
            this.getList()
        })
    }
    onReset = () => {
        this.setState({
            values: {}
        }, () => {
            this.getList();
        })
    }

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };

    getList() {
        this.setState({loading: true})
        HttpUtils.postForm(this.props.url, {
            ...this.state.form,
            order: 'id desc',
            ...this.props.value,
            ...this.state.values
        }).then(res => {
            this.setState({loading: false})
            if (res.status === 10000) {
                let newArray = []

                if(this.props.timeObj){
                    res.data.map(item=>{
                        let temp = item
                        temp.start_day = this.props.timeObj.start_day
                        temp.start_hour =this.props.timeObj.start_hour
                        temp.start_minute =this.props.timeObj.start_minute
                        temp.end_day =this.props.timeObj.end_day
                        temp.end_hour =this.props.timeObj.end_hour
                        temp.end_minute =this.props.timeObj.end_minute
                        newArray.push(temp)
                    })
                }
                this.setState({
                    dataSource: this.props.timeObj?newArray:res.data,
                    count: res.count,
                })
            }
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    _getCurrent = (page) => {
        this.setState({
            form: {
                page: page,
                pers: 100
            }
        }, () => {
            this.getList()
        })
    }

    _changePers = (current, size) => {
        this.setState({
            form: {
                page: 1,
                pers: 100
            }
        }, () => {
            this.getList();
        })
    }

    render() {
        const {scroll, columns, pagination, checked_box} = this.props;
        const {loading, dataSource, count, form, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>


                <Table
                    rowKey={'id'}
                    rowSelection={checked_box ? rowSelection : null}
                    scroll={scroll}
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    // pagination={pagination ? {
                    //
                    //     showSizeChanger: true,
                    //     onShowSizeChange: this._changePers,
                    //     pageSizeOptions: ['10', '30', '50', '100'],
                    //     showQuickJumper: true,
                    //     current: form.page,
                    //     total: count,
                    //     onChange: this._getCurrent
                    // } : null}
                />
                <div style={{width:'100%',display:'flex',justifyContent:'flex-end',paddingTop:10}}>
                    {checked_box &&
                    <>

                        <ConfirmPop
                            disabled={!hasSelected} loading={loading}
                            onConfirm={()=>{
                                this.props.checkConfirm && this.props.checkConfirm(selectedRowKeys);
                                console.log(this.state.dataSource)
                            }}
                            text={this.props.title && this.props.title}
                            message={`确定要${this.props.title && this.props.title}吗？`}
                            onSubmit={() => {
                                console.log('哈哈')
                                this.custom_table.getList()
                            }}
                        >
                        </ConfirmPop>


                    </>

                    }
                </div>
            </div>
        );
    }
}
