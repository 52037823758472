import React, {Component} from 'react';
import {Card, PageHeader,Switch} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "../../components/Filter";
import ChangeUserInfo from './components/ChangeUserInfo'
import CreateExamine from './components/CreateExamine'
import CustomPopconfirm from "../../components/CustomPopconfirm";
import LookExamine from "./components/LookExamine";
import LookImages from "./components/LookImages";
import LookImages2 from "./components/LookImages2";
import HttpUtils from '../../utils/HttpUtils'

const select = [
    {
        name: '筛选时间',
        type: 'date',
        dataIndex: ['start_time', 'end_time']
    },
    {
        name: '登录状态',
        type: 'select',
        option: [{id: 1, name: '可以登录'}, {id: 2, name: '禁止登录'}],
        dataIndex: 'status'
    },
    {
        name: '人员编号/手机号/姓名',
        type: 'input',
        dataIndex: 'key',
        inputWidth:450
    },
    {
        name: '性别',
        type: 'select',
        option: [{id: 'M', name: '男'}, {id: 'F', name: '女'},{id: 'U', name: '未知'}],
        dataIndex: 'gender',
        wrap:4
    },
    {
        name: '是否管理员',
        type: 'select',
        option: [{id: 1, name: '是管理员'}, {id: 2, name: '不是管理员'}],
        dataIndex: 'is_checker',
        wrap:20
    },
]

export default class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: '',
            deptList:[]

        }

        this.columns = [
            {
                width: 150,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {return moment(text).format('YYYY-MM-DD  HH:mm:ss') }
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                key: 'avatar',
                width: 100,
                render:(text => (
                    <img src={text} style={{maxHeight:60,maxWidth:60}}  />
                ))
            },
            {
                title: '微信昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                width: 100,
            },
            {
                title: '手机号',
                dataIndex: 'mobile_phone',
                key: 'mobile_phone',
                width: 150,
            },
            {
                title:'所属部门',
                dataIndex:'dept_id',
                key: 'dept_id',
                width: 100,
                render:text=>{
                    if(text === 0){
                        return '未分类'
                    }else {
                        // console.log('让我康康',)
                        if (this.state.deptList.length > 0 && this.state.deptList.filter(item=>{return item.id  === text}).length > 0 ){
                            return this.state.deptList.filter(item=>{return item.id  === text})[0].name
                        }
                    }
                }
            },
            {
                title: '人员编号',
                dataIndex: 'pin',
                key: 'pin',
                width: 100,
            },
            {
                title: '姓名(人员姓名)',
                dataIndex: 'name',
                key: 'name',
                width: 120,
                render:((text,record)=>{
                    if(record.last_name&&record.last_name.length>0){
                        return `${text}(${record.last_name})`
                    }else {}
                    return  text
                })
            },
            {
                title: '性别',
                dataIndex: 'gender',
                key: 'gender',
                width: 100,
                render:(text=>{
                    switch (text) {
                        case '' : return '未知'
                        case 'M' : return '男'
                        case 'F' : return '女'
                    }
                })
            },
            {
                title: '所在城市',
                dataIndex: 'city',
                key: 'city',
                width: 100,
            },
            {
                title: '是否上传门禁照片',
                dataIndex: 'face_image_count',
                key: 'face_image_count',
                width: 130,
                render:(text)=>text && text > 0 ? '已上传':'未上传'
            },
            {
                title: '是否上传身份证照片',
                dataIndex: 'id_card_count',
                key: 'id_card_count',
                width: 130,
                render:(text)=>text && text > 0 ? '已上传':'未上传'
            },

            {
                title: '是否管理员',
                dataIndex: 'is_checker',
                width: 100,
                key: 'is_checker',
                render: (text, item) => (
                    <Switch  checkedChildren="是"  unCheckedChildren="否" checked={text === 1} onChange={(value) => {
                        this.changeChecker(value, item)
                    }}/>
                )
            },
            {
                title: '登录状态',
                dataIndex: 'status',
                width: 100,
                key: 'status',
                render: (text, item) => (
                    <Switch  checkedChildren="是"  unCheckedChildren="否" checked={text === 1} onChange={(value) => {
                        this.changeStatus(value, item)
                    }}/>
                )
            },

            {
                title: '操作',
                width: 350,

                render: (text,record) => (
                    <>
                        {
                            record.pin.length >0 ? <>
                                <CustomPopconfirm
                                    text={'解除绑定'} message={'确定解除绑定吗？'} url={'/api/user/relieve/bind'}
                                    value={{id: record.id}}
                                    onSubmit={() => {
                                        this.custom_table.getList()
                                    }}
                                /> <br/> </> :null
                        }
                        {
                            record.is_checker === 1 ?<>
                                <CreateExamine item={record}  id={record.id} refreshList={()=>{
                                    this.custom_table.getList();
                                }}  /> <br/>
                            </> :null
                        }
                        <LookExamine item={record}/> <br/>
                        <LookImages item={record} id={record.id} refreshList={()=>{
                            this.custom_table.getList();
                        }}/>
                        <LookImages2 item={record} id={record.id} refreshList={()=>{
                            this.custom_table.getList();
                        }}/>
                        <br/>
                        <ChangeUserInfo item={record} id={record.id} refreshList={()=>{
                            this.custom_table.getList();
                        }} />
                    </>
                )
            }
        ]

    }

    componentDidMount() {
        this.getList()
    }

    getList() {
         HttpUtils.postForm('/api/dept/list', {}).then(res => {
            if (res.status === 10000) {
                if (res.data.length > 0){
                    this.setState({
                        deptList:res.data
                    })
                }
            }
        })
    }


    changeChecker = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/user/update', {
            is_checker: value ? 1 : 2,
            id:item.id
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    changeStatus = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/user/update', {
            status: value ? 1 : 2,
            id:item.id
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    render() {
        return (
            <div>
                <Card style={{marginBottom:15}}>
                    <PageHeader title="用户列表" subTitle="" />
                </Card>
                <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                <Card bordered={false}>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/user/list'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
            </div>
        );
    }
}



