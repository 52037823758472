import React, {Component} from 'react';
import {Card, PageHeader, Switch, notification} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "../../components/Filter";
import HttpUtils from "../../utils/HttpUtils";
import AddExamine from "./components/AddExamine";
import ConfirmPop from './components/ConfirmPop'
import './RoomExamine.scss'
import LookImages from "../User/components/LookImages";
import LookImages2 from "../User/components/LookImages2";

const select = [
    {
        name: '创建时间',
        type: 'date',
        dataIndex: ['start_time', 'end_time']
    },
    {
        name: '预约日期',
        type: 'date',
        format : 'YYYY-MM-DD',
        dataIndex: ['start_date', 'end_date']
    },
];
export default class RoomExamineList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: ''

        }

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {
                    return moment(text).format('YYYY-MM-DD  HH:mm:ss')
                }
            },
            {
                title: '流水号',
                dataIndex: 'tx_no',
                key: 'tx_no',
                width: 200,
            },
            {
                title: '真实姓名',
                dataIndex: 'full_name',
                key: 'full_name',
                width: 100,
            },
            {
                title: '手机号',
                dataIndex: 'user.mobile_phone',
                key: 'user.mobile_phone',
                width: 120,
            },
            {
                title: '房间种类',
                dataIndex: 'room_category.name',
                key: 'room_category.name',
                width: 100,
            },
            {
                title: '房间名',
                dataIndex: 'room.name',
                key: 'room.name',
                width: 100,
            },
            {
                title: '开始时间',
                dataIndex: 'start_time',
                key: 'start_time',
                width: 150,
                render: (text, record) => {
                    if(text){
                        return record.date + ' ' + text.substr(0, 5)
                    }else {
                        return record.start_date
                    }
                }
            },
            {
                title: '结束时间',
                dataIndex: 'end_time',
                key: 'end_time',
                width: 150,
                render: (text, record) => {
                    if(text){
                        return record.date + ' ' + text.substr(0, 5)
                    }else {
                        return record.end_date
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                width: 150,
                render: (text) => {
                    return <>
                        <p className={'wrap'}>{text}</p>
                    </>
                }
            },
            {
                title: '审核状态',
                dataIndex: 'status',
                key: 'status',
                width: 120,
                render: (text => {
                    switch (text) {
                        case 1 :
                            return '审核中';
                        case 2 :
                            return '审核通过';
                        case 3 :
                            return '审核拒绝';
                    }
                })
            },
            {
                title: '操作',
                width: 300,

                render: (text, record) => (
                    <>
                        {
                            record.status === 1 ? <>
                                <AddExamine item={record} id={record.id} refreshList={() => {
                                    this.custom_table.getList();
                                }}/><br/>
                            </> : null
                        }
                        {
                            record.status === 1 ? <>
                                <LookImages item={record} refreshList={() => {
                                    this.custom_table.getList();
                                }}/>
                            </> : null
                        }
                        {
                            record.status === 1 ? <>
                                <LookImages2 item={record} id={record.user_id}/><br/>
                            </> : null
                        }
                        <br/>
                    </>
                )
            }
        ]

    }


    openNotificationWithIcon = (type, successData, failureData) => {
        notification[type]({
            duration: null,
            message: '审核结果',
            description: <>
                {successData && <div style={{marginBottom: 10}}>
                    <span style={{color: '#87d068', fontSize: 18}}>审核通过</span>
                    {successData.map(item => (
                        <div>
                            <div>流水号:{item.tx_no}</div>
                        </div>
                    ))}
                </div>}
                {
                    failureData && <div>
                        <span style={{color: '#f50', fontSize: 18}}>审核拒绝</span>
                        {failureData.map(item => (
                            <div style={{marginBottom: 10}}>
                                <div>流水号:{item.tx_no}</div>
                                <div>原因:{item.reason}</div>
                            </div>
                        ))}
                    </div>
                }
            </>

        });
    };

    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    checkConfirm = (ids) => {
        const loading = window.$message.loading('发送中', 0)

        HttpUtils.postForm('/api/room/examine/record/batch/update', {
            reserve_ids: ids.join(','),
            status: 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                if (res.data.examine_failure_data === null) {
                    this.openNotificationWithIcon('success', res.data.examine_success_data) //全部成功

                } else if (res.data.examine_success_data === null) {
                    this.openNotificationWithIcon('warning', res.data.examine_success_data, null, res.data.examine_failure_data) //全部失败

                } else {
                    this.openNotificationWithIcon('warning', res.data.examine_success_data, res.data.examine_failure_data) //有失败，有成功
                }
            } else {
                window.$message.error(res.message);
            }
            this.custom_table.getList();
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    };


    render() {
        return (
            <div>
                <Card style={{marginBottom: 15}}>
                    <PageHeader title="预定审核" subTitle=""/>
                </Card>
                <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                <Card bordered={false}>
                    <CustomTable
                        title={'批量通过'}
                        checkConfirm={(id) => {
                            this.checkConfirm(id)
                        }}
                        checked_box
                        scroll={{x: 1600}}
                        value={{status: 1}}
                        url={'/api/room/reservation/record'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
            </div>
        );
    }
}



