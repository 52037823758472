import React, {Component} from 'react';
import {Modal} from 'antd';
import Form from "./Form2";

export default class AddExamine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.content = [
            {
                name: '审核结果',
                type: 'select',
                dataIndex: 'status',
                labelCol: 5,
                required: true,
                wrapperCol: 14,
                message: '请选择',
                option: [
                    {id: 2, name: '通过'},
                    {id: 3, name: '拒绝'}
                ]
            },
            {
                name: '拒绝原因',
                type: 'textarea',
                dataIndex: 'reason',
                placeholder:'拒绝必填',
                labelCol: 5,
                wrapperCol: 16,
                message:'请填写拒绝原因'
            },
        ]
    }

    componentDidMount() {
    }


    onSubmit = () => {
        this.props.refreshList();
        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {

        return (
            <>
                <a onClick={() => {
                    this.setState({visible: true})
                }}>审核</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="审核"
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <Form
                        content={this.content} onSubmit={this.onSubmit} url={'/api/room/examine/record/update'}
                        values={this.props.item}
                        value={{id:this.props.item.id}}
                    />
                </Modal>
            </>
        );
    }
}
