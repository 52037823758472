import React, {Component} from 'react';
import moment from 'moment';
import {Form, Select, Input, DatePicker, Card, Button, Row, Cascader, Col} from 'antd';

const Option = Select.Option;
const FormItem = Form.Item;

class FilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.values) {
            this.props.form.setFieldsValue({...this.props.values})
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={this.props.title} bordered={false}>
                <Form
                >
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',minWidth:500}}>

                        <Row gutter={18}>
                            {this.props.select.map((item, index) => {
                                const labelCol = {span: 8}
                                const wrapperCol = {span: 14}
                                // const {getFieldDecorator} = props.form;
                                switch (item.type) {
                                    case 'input':
                                        return (
                                            <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 140}
                                                 key={index}>
                                                <FormItem
                                                    label={item.name}
                                                    labelCol={labelCol}
                                                    wrapperCol={wrapperCol}
                                                    style={{width:item.inputWidth?item.inputWidth:'',marginBottom:0,marginRight:20,marginLeft:item.inputLeft?item.inputLeft:''}}
                                                >
                                                    {getFieldDecorator(item.dataIndex, {})(
                                                        <Input style={{marginLeft:item.seachLeft?item.seachLeft:''}} allowClear name={item.dataIndex}/>
                                                    )}
                                                </FormItem>
                                            </Col>
                                        );
                                    case 'date':
                                        return (
                                            <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                                 key={index}>
                                                <FormItem
                                                    label={item.name}
                                                    labelCol={{span: 4}}
                                                    wrapperCol={{span: 20}}
                                                >
                                                    {getFieldDecorator(item.dataIndex[0], {
                                                        // initialValue:item.defaultValue?item.defaultValue[0]:''
                                                    })(
                                                        <DatePicker
                                                            placeholder={item.dataIndex.length > 1 ? '开始时间' : '选择时间'}
                                                            format={item.format ? item.format : 'YYYY-MM-DD HH:mm:ss'}
                                                            name={item.dataIndex[0]}
                                                            showTime
                                                            // size={'small'}
                                                        />
                                                    )}
                                                    {item.dataIndex.length > 1 &&
                                                    <>
                                                        &nbsp;-&nbsp;
                                                        {getFieldDecorator(item.dataIndex[1], {
                                                            // initialValue:item.defaultValue?item.defaultValue[1]:''
                                                        })(
                                                            <DatePicker
                                                                placeholder="结束时间"
                                                                format={item.format ? item.format : 'YYYY-MM-DD HH:mm:ss'}
                                                                name={item.dataIndex[1]}
                                                                showTime
                                                                // size={'small'}
                                                            />
                                                        )}
                                                    </>
                                                    }
                                                </FormItem>
                                            </Col>
                                        );
                                    case 'select':
                                        return (
                                            <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                                 key={index}>
                                                <FormItem
                                                    label={item.name}
                                                    labelCol={labelCol}
                                                    wrapperCol={wrapperCol}
                                                >
                                                    {getFieldDecorator(item.dataIndex, {})(
                                                        <Select
                                                            placeholder="请选择"
                                                            name={item.dataIndex}
                                                            style={{width: 160}}
                                                        >
                                                            {item.option.map((item, index) => {
                                                                return (
                                                                    <Option key={index}
                                                                            value={String(item.id)}>{item.name}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Col>
                                        );
                                    case 'cascader':
                                        return (
                                            <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                                 key={index}>
                                                <Form.Item
                                                    label={item.name}
                                                    labelCol={labelCol}
                                                    wrapperCol={wrapperCol}
                                                >
                                                    {getFieldDecorator(item.dataIndex, {
                                                        rules: [
                                                            {
                                                                type: 'array',
                                                                // required: true,
                                                                // message: 'Please select your habitual residence!'
                                                            },
                                                        ],
                                                        // getValueFromEvent: (e) => {
                                                        //     console.log(e)
                                                        // }
                                                    })(<Cascader options={item.option} placeholder={"请选择"}/>)}
                                                </Form.Item>
                                            </Col>

                                        )
                                }
                            })}
                        </Row>
                        <div
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            {this.props.hiddenReset ? null :
                                <Button
                                    onClick={() => {
                                        this.props.form.resetFields();
                                        this.props.onReset && this.props.onReset();
                                    }}
                                    type="normal"
                                >
                                    重置
                                </Button>
                            }

                            <Button
                                onClick={() => {
                                    this.props.form.validateFields((err, values) => {
                                        if (values.start_time) {
                                            if (this.props.format) {
                                                values.start_time = moment(values.start_time).format(this.props.format)
                                                values.end_time = moment(values.end_time).format(this.props.format)
                                            } else {
                                                values.start_time = moment(values.start_time).format('YYYY-MM-DD HH:mm:ss')
                                                values.end_time = moment(values.end_time).format('YYYY-MM-DD HH:mm:ss')
                                            }
                                        }
                                        this.props.onSubmit(values)
                                    })
                                }}
                                type="primary"
                                style={{marginLeft: '10px'}}
                            >
                                {this.props.confirmTxt ? this.props.confirmTxt : '确定'}
                            </Button>
                        </div>
                    </div>

                </Form>
            </Card>
        );
    }
}

const WrappedFilterForm = Form.create()(FilterForm);
export default WrappedFilterForm;

