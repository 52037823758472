import React, {Component} from 'react';
import {Popconfirm,Button} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";
import PropTypes from 'prop-types';


export default class CustomPopconfirm extends Component {

    static defaultProps = {}

    static propTypes = {
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        value: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }



    render() {
        return (
            <Popconfirm title={this.props.message} onConfirm={this.props.onConfirm} >
                        <Button   disabled={this.props.disabled} loading={this.props.loading}  type={'primary'}  style={{marginRight:10}}>{this.props.text}</Button>
            </Popconfirm>
        );
    }
}
