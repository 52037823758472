import React, {Component} from 'react';
import './Login.scss';
import CustomLogin from '../../components/CustomLogin1';

import {Input, Icon, Button} from 'antd';


export default class Login extends Component {
    static displayName = 'Login';

    constructor(props) {
        super(props);
        this.state = {

        };

    }


    render() {
        return (
            <div className="login-page">
                <div style={{marginBottom:10}}>
                    <img src={require('../../assets/image/picture1.png')} style={{width:'100px'}} alt=""/>
                </div>
                <div style={{color:'#fff',fontSize:26,fontWeight:'bold',marginTop:10}}>天地人房间预约系统</div>
               <div className='login-panel'>
                   <div style={{color:'#484848',fontSize:16,fontWeight:'normal',marginTop:10,marginBottom:20,textAlign:'center'}}>管理员登录</div>
                   <CustomLogin code={true} loginUrl={'/api/auth/login'} onPush={()=>{
                     // TODO 登录后跳转地址
                     this.props.history.push('/home/thirdPartyList')
                   }}/>
                </div>
            </div>
        );
    }
}
