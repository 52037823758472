import React, {Component} from 'react';
import moment from 'moment';
import {Form, Select, Input, DatePicker, Card, Button, Cascader, Upload, Icon, Modal} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";
import ImgCrop from "antd-img-crop";

const Option = Select.Option;
const FormItem = Form.Item;

class CustomForm extends Component {
    imageDataIndex

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
        }
    }

    componentDidMount() {
        if (this.props.values) {
            this.props.form.setFieldsValue({...this.props.values})
            if (this.props.imageDataIndex) {
                this.props.form.setFieldsValue({[this.props.imageDataIndex]: this.props.values[this.props.imageDataIndex]})
                this.setState({
                    fileList: [
                        {
                            uid: '-1',
                            name: 'xxx.png',
                            status: 'done',
                            url: this.props.values[this.props.imageDataIndex]
                        },
                        // {
                        //     uid: '-2',
                        //     name: 'xxx.png',
                        //     status: 'done',
                        //     url: this.props.values[this.props.imageDataIndex]
                        // },
                    ]
                })
            }

        }
    }

    componentWillReceiveProps(nextProps) {
    }


    onSubmit = (values) => {
        if (this.props.imageDataIndex) {
            if (!values[this.props.imageDataIndex]) {
                window.$message.warn('请上传图片')
                return;
            }
        }
        this.setState({loading: true})
        HttpUtils.postForm(this.props.url, {
            ...this.props.value,
            ...values,
        }).then(res => {
            this.setState({loading: false})
            if (res.status === 10000) {
                window.$message.success('提交成功');
                this.props.onSubmit()
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            this.setState({loading: false})
            window.$message.error('通讯失败')
        })
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleChange = (fileList, key) => {
        this.setState({fileList});
        if (fileList.length === 0) {
            this.props.form.setFieldsValue({[key]: ''})
        } else if (fileList[0].response) {
            this.props.form.setFieldsValue({[key]: fileList[0].response.url})
        }

    }

    normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        console.log(e);
        return e && e.fileList;
    };

    handleCancel = () => this.setState({previewVisible: false})

    render() {
        const {previewVisible, previewImage, fileList} = this.state;
        const {getFieldDecorator} = this.props.form;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (
            <Card title={this.props.title} bordered={false}>
                <Form
                    labelCol={{xs: {span: 24}, sm: {span: 8}}}
                    // layout="inline"
                >
                    {this.props.content.map((item) => {
                        const labelCol = {span: 6}
                        const wrapperCol = {span: 14}
                        switch (item.type) {
                            case 'input':
                                return (
                                    <FormItem
                                        key={item.dataIndex}
                                        label={item.name}
                                        labelCol={{span: item.labelCol}}
                                        wrapperCol={{span: item.wrapperCol}}
                                    >
                                        {getFieldDecorator(item.dataIndex, {
                                            rules: [
                                                {
                                                    required: item.required,
                                                    message: item.message
                                                },
                                            ],
                                        })(
                                            <Input allowClear name={item.dataIndex}/>
                                        )}
                                    </FormItem>
                                );
                            case 'date':
                                return (
                                    <FormItem
                                        key={item.dataIndex}
                                        label={item.name}
                                        labelCol={{span: item.labelCol}}
                                        wrapperCol={{span: item.wrapperCol}}
                                    >
                                        {getFieldDecorator(item.dataIndex[0], {
                                            rules: [
                                                {
                                                    required: item.required,
                                                    message: item.message
                                                },
                                            ],
                                        })(
                                            <DatePicker
                                                placeholder={item.dataIndex.length > 1 ? '开始时间' : '选择时间'}
                                                format={item.format ? item.format : 'YYYY-MM-DD HH:mm:ss'}
                                                name={item.dataIndex[0]}
                                                showTime
                                                // size={'small'}
                                            />
                                        )}
                                        {item.dataIndex.length > 1 &&
                                        <>
                                            &nbsp;-&nbsp;
                                            {getFieldDecorator(item.dataIndex[1], {
                                                rules: [
                                                    {
                                                        required: item.required,
                                                        message: item.message
                                                    },
                                                ],
                                            })(
                                                <DatePicker
                                                    placeholder="结束时间"
                                                    format={item.format ? item.format : 'YYYY-MM-DD HH:mm:ss'}
                                                    name={item.dataIndex[1]}
                                                    showTime
                                                    // size={'small'}
                                                />
                                            )}
                                        </>
                                        }
                                    </FormItem>
                                );
                            case 'select':
                                return (
                                    <FormItem
                                        key={item.dataIndex}
                                        label={item.name}
                                        labelCol={{span: item.labelCol}}
                                        wrapperCol={{span: item.wrapperCol}}
                                    >
                                        {getFieldDecorator(item.dataIndex, {
                                            rules: [{required: item.required, message: item.message}],
                                        })(
                                            <Select
                                                placeholder="请选择"
                                                name={item.dataIndex}
                                                style={{width: 120}}
                                                // value={this.props.values[item.dataIndex]}

                                            >
                                                {item.option.map((items, index) => {
                                                    return (
                                                        <Option key={index}
                                                                value={items.id}>{items.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                );
                            case 'image':
                                return (
                                    <Form.Item label={item.name} labelCol={{span: item.labelCol}}
                                               wrapperCol={{span: item.wrapperCol}}>
                                        {getFieldDecorator(item.dataIndex, {
                                            valuePropName: 'fileList',
                                            getValueFromEvent: this.normFile,
                                        })(
                                            <div style={{width: 100, height: 100}}>
                                                <ImgCrop
                                                    resize={true}
                                                    width={1500}
                                                    height={800}
                                                >
                                                    <Upload
                                                        style={{flexDirection: 'row', alignItems: 'center'}}
                                                        action="/api/file/file/upload"
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={this.handlePreview}
                                                        onChange={({fileList}) => {
                                                            this.handleChange(fileList, item.dataIndex)
                                                        }}
                                                    >
                                                        {fileList.length >= 1 ? null : uploadButton}
                                                    </Upload>
                                                </ImgCrop>
                                                <Modal visible={previewVisible} footer={null}
                                                       onCancel={this.handleCancel}>
                                                    <img alt="上传的图片" style={{width: '100%'}} src={previewImage}/>
                                                </Modal>
                                            </div>
                                        )}
                                    </Form.Item>
                                )
                            case 'cascader':
                                return (
                                    <Form.Item
                                        key={item.dataIndex}
                                        label={item.name}
                                        labelCol={{span: item.labelCol}}
                                        wrapperCol={{span: item.wrapperCol}}
                                    >
                                        {getFieldDecorator(item.dataIndex, {
                                            rules: [
                                                {
                                                    type: 'array',
                                                    required: item.required,
                                                    // message: 'Please select your habitual residence!'
                                                },
                                            ],
                                            // getValueFromEvent: (e) => {
                                            //     console.log(e)
                                            // }
                                        })(<Cascader options={item.option} placeholder={"请选择"}/>)}
                                    </Form.Item>
                                )
                            case 'textarea':
                                return (
                                    <FormItem
                                        key={item.dataIndex}
                                        label={item.name}
                                        labelCol={{span: item.labelCol}}
                                        wrapperCol={{span: item.wrapperCol}}
                                    >
                                        {getFieldDecorator(item.dataIndex, {
                                            rules: [{required: item.required, message: item.message}]
                                        })(
                                            <Input.TextArea
                                                placeholder={item.placeholder ? item.placeholder : "请输入"}
                                                name={item.dataIndex}
                                                style={{height: 120, resize: 'none'}}
                                            />
                                        )}
                                    </FormItem>
                                );
                        }
                    })}
                </Form>
                <div
                    style={{
                        textAlign: 'left',
                        marginLeft: '12px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        loading={this.state.loading}
                        onClick={() => {
                            this.props.form.validateFields((err, values) => {
                                if (err) {
                                    return;
                                }
                                if (values.start_time) {
                                    if (this.props.format) {
                                        values.start_time = moment(values.start_time).format(this.props.format)
                                        values.end_time = moment(values.end_time).format(this.props.format)
                                    } else {
                                        values.start_time = moment(values.start_time).format('YYYY-MM-DD HH:mm:ss')
                                        values.end_time = moment(values.end_time).format('YYYY-MM-DD HH:mm:ss')
                                    }
                                }
                                this.onSubmit(values)
                            })
                        }}
                        type="primary"
                        style={{marginLeft: '10px'}}
                    >
                        {this.props.confirmTxt ? this.props.confirmTxt : '确定'}
                    </Button>
                </div>
            </Card>
        );
    }
}

const WrappedCustomForm = Form.create()(CustomForm);
export default WrappedCustomForm;

