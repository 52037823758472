import React, {Component} from 'react';
import {Card, PageHeader, Switch, Button, Modal} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
// import FilterForm from "../components/Filter";
import HttpUtils from "../../utils/HttpUtils";
import CreateDepartment from "./component/CreateDepartment"
import ChangeDepartment from './component/ChangeDepartment'
import ChangeExamineInfo from "../Room/components/ChangeExamineInfo";
import CustomPopconfirm from "../../components/CustomPopconfirm";



export default class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: '',
        }
        this.select = [
            {
                name: '部门种类',
                type: 'selectList',
                dataIndex: 'room_category_id'
            },
            {
                name: '部门名',
                type: 'input',
                dataIndex: 'name',
                inputWidth:450
            },
            {
                name: '能否预约',
                type: 'select',
                option: [{id: 1, name: '能预约'}, {id: 2, name: '不能预约'}],
                dataIndex: 'is_reservation'
            },
            {
                name: '是否展示',
                type: 'select',
                option: [{id: 1, name: '展示'}, {id: 2, name: '不展示'}],
                dataIndex: 'status'
            },

        ];

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {
                    return moment(text).format('YYYY-MM-DD  HH:mm:ss')
                }
            },
            {
                title: '所属部门',
                dataIndex: 'name',
                key: 'name',
                width: 120,
            },
            {
                title: '人脸库名称',
                dataIndex: 'library_name',
                key: 'library_name',
                width: 120,
            },
            {
                title: '人脸库描述',
                dataIndex: 'library_describe',
                key: 'library_describe',
                width: 120,
                render:text=> text ? text : '/'
            },
            {
                title: '人脸库质量',
                dataIndex: 'library_quality',
                key: 'library_quality',
                width: 120,
                render:text=>{
                    switch (text) {
                        case '' : return '/';
                        case 'normal' : return '普通';
                        case 'hight' : return '高质量';
                        default : return  '/'
                    }
                }
            },
            {
                width: 140,
                title: '是否展示',
                dataIndex: 'status',
                key: 'status',
                render: (text, item) => (
                    <Switch checked={text} onChange={(value) => {
                        this.changeStatus(value, item)
                    }}/>
                )
            },
            {
                title: '操作',
                width: 300,
                render: (text,record) => (
                    <>
                        <ChangeDepartment item={record}  id={record.id} refreshList={()=>{
                            this.custom_table.getList();
                        }}  /><br/>
                    </>
                )
            }
        ]
    }


    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    changeStatus = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/dept/update', {
            ...item,
            status: value ? 1 : 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    changeStatus2 = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/room/update', {
            ...item,
            is_reservation: value ? 1 : 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    render() {
        return (
            <div style={{position:'relative'}}>

                <Card style={{marginBottom: 15}}>
                    <PageHeader title="部门列表" subTitle=""/>

                </Card>
                {/*<FilterForm select={this.select} categoryList={this.state.CategoryList} onSubmit={this.onSubmit}*/}
                {/*            onReset={this.onReset}/>*/}

                <Card bordered={false}>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/dept/list'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
                <CreateDepartment  refreshList={()=>{this.custom_table.getList()}}  />
            </div>
        );
    }
}



