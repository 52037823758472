import React, {Component} from 'react';
import moment from 'moment';
import {Form, Select, Input, DatePicker, Card, Button, Row, Cascader, Col} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";

const Option = Select.Option;
const FormItem = Form.Item;

class FilterForm extends Component {
    constructor(props) {
        super(props);
        this.state={
            categoryList:[]
        }
    }

    componentDidMount() {
        this.getCategoryList()
        if (this.props.values) {
            this.props.form.setFieldsValue({...this.props.values})
        }
    }


    getCategoryList() {
        HttpUtils.postForm('/api/room/category/list', {
            pers:100
        }).then(res => {
            this.setState({
                categoryList:res.data.map(item=>({
                    id:item.id,
                    name:item.name
                }))
            })
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={this.props.title} bordered={false}>
                <Form
                    // layout="inline"
                >
                    <Row gutter={18}>
                        {this.props.select.map((item, index) => {
                            const labelCol = {span: 8}
                            const wrapperCol = {span: 14}
                            // const {getFieldDecorator} = props.form;
                            switch (item.type) {
                                case 'input':
                                    return (
                                        <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                             key={index}>
                                            <FormItem
                                                label={item.name}
                                                labelCol={labelCol}
                                                wrapperCol={wrapperCol}
                                                style={{width:item.inputWidth?item.inputWidth:''}}
                                            >
                                                {getFieldDecorator(item.dataIndex, {})(
                                                    <Input allowClear name={item.dataIndex}/>
                                                )}
                                            </FormItem>
                                        </Col>
                                    );
                                case 'date':
                                    return (
                                        <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                             key={index}>
                                            <FormItem
                                                label={item.name}
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 20}}
                                            >
                                                {getFieldDecorator(item.dataIndex[0], {
                                                    // initialValue:item.defaultValue?item.defaultValue[0]:''
                                                })(
                                                    <DatePicker
                                                        placeholder={item.dataIndex.length > 1 ? '开始时间' : '选择时间'}
                                                        format={item.format ? item.format : 'YYYY-MM-DD HH:mm:ss'}
                                                        name={item.dataIndex[0]}
                                                        showTime
                                                        // size={'small'}
                                                    />
                                                )}
                                                {item.dataIndex.length > 1 &&
                                                <>
                                                    &nbsp;-&nbsp;
                                                    {getFieldDecorator(item.dataIndex[1], {
                                                        // initialValue:item.defaultValue?item.defaultValue[1]:''
                                                    })(
                                                        <DatePicker
                                                            placeholder="结束时间"
                                                            format={item.format ? item.format : 'YYYY-MM-DD HH:mm:ss'}
                                                            name={item.dataIndex[1]}
                                                            showTime
                                                            // size={'small'}
                                                        />
                                                    )}
                                                </>
                                                }
                                            </FormItem>
                                        </Col>
                                    );
                                case 'select':
                                    return (
                                        <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                             key={index}>
                                            <FormItem
                                                label={item.name}
                                                labelCol={labelCol}
                                                wrapperCol={wrapperCol}
                                            >
                                                {getFieldDecorator(item.dataIndex, {})(
                                                    <Select
                                                        placeholder="请选择"
                                                        name={item.dataIndex}
                                                        style={{width: 160}}
                                                    >
                                                        {item.option.map((item, index) => {
                                                            return (
                                                                <Option key={index}
                                                                        value={String(item.id)}>{item.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                    );
                                case 'selectList':
                                    return (
                                        <Col xxl={item.wrap ? item.wrap : 8} sm={item.wrap ? item.wrap : 12}
                                             key={index}>
                                            <FormItem
                                                label={item.name}
                                                labelCol={labelCol}
                                                wrapperCol={wrapperCol}
                                            >
                                                {getFieldDecorator(item.dataIndex, {})(
                                                    <Select
                                                        placeholder="请选择"
                                                        name={item.dataIndex}
                                                        style={{width: 160}}
                                                    >
                                                        {this.state.categoryList.map((item, index) => {
                                                            return (
                                                                <Option key={index}
                                                                        value={String(item.id)}>{item.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                    );
                            }
                        })}
                    </Row>
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '12px',
                        }}
                    >
                        {this.props.hiddenReset ? null :
                            <Button
                                onClick={() => {
                                    this.props.form.resetFields();
                                    this.props.onReset && this.props.onReset();
                                }}
                                type="normal"
                            >
                                重置
                            </Button>
                        }

                        <Button
                            onClick={() => {
                                this.props.form.validateFields((err, values) => {
                                    if (values.start_time) {
                                        if (this.props.format) {
                                            values.start_time = moment(values.start_time).format(this.props.format)
                                            values.end_time = moment(values.end_time).format(this.props.format)
                                        } else {
                                            values.start_time = moment(values.start_time).format('YYYY-MM-DD HH:mm:ss')
                                            values.end_time = moment(values.end_time).format('YYYY-MM-DD HH:mm:ss')
                                        }
                                    }
                                    this.props.onSubmit(values)
                                })
                            }}
                            type="primary"
                            style={{marginLeft: '10px'}}
                        >
                            {this.props.confirmTxt ? this.props.confirmTxt : '确定'}
                        </Button>
                    </div>
                </Form>
            </Card>
        );
    }
}

const WrappedFilterForm = Form.create()(FilterForm);
export default WrappedFilterForm;

