import BlankLayout from './layouts/BlankLayout';
import SliderLayout from './layouts/SliderLayout';
import UserList from './pages/User/UserList'
import ThirdPartyList from './pages/User/ThirdPartyList'
import ImageCheckList from './pages/User/ImageCheckList'
import CategoryList from './pages/Room/CategoryList'
import CarouselList from './pages/Carousel/CarouselList'
import RoomList from './pages/Room/RoomList'
import RoomAppointList from './pages/Room/RoomAppointList'
import RoomExamineList from './pages/Room/RoomExamineList'
import Config from './pages/Config'
import DepartmentList from './pages/Department/DepartmentList'

import Home from './pages/Home';
import Login from './pages/Login';


const routerConfig = [
    {
        path: '/',
        layout: BlankLayout,
        component: Login,
    },
    {
        path: '/home',
        layout: SliderLayout,
        component: Home,
        children: [
            {
                path: '/appointExamineList',
                layout: SliderLayout,
                component: RoomExamineList
            },
            {
                path: '/thirdPartyList',
                layout: SliderLayout,
                component: ThirdPartyList,
            },
            {
                path: '/imageCheckList',
                layout: SliderLayout,
                component: ImageCheckList,
            },
        ]

    },
    {
        path: '/user',
        layout: SliderLayout,
        children: [
            {
                path: '/list',
                layout: SliderLayout,
                component: UserList,
            },
        ]
    },
    {
        path: '/room',
        layout: SliderLayout,
        children: [
            {
                path: '/categoryList',
                layout: SliderLayout,
                component: CategoryList,
            },
            {
                path: '/list',
                layout: SliderLayout,
                component: RoomList,
            },
            {
                path: '/appointList',
                layout: SliderLayout,
                component: RoomAppointList
            },
        ]
    },
    {
        path: '/department',
        layout: SliderLayout,
        children: [
            {
                path: '/list',
                layout: SliderLayout,
                component: DepartmentList,
            },
        ]
    },
    {
        path: '/config',
        layout: SliderLayout,
        children: [
            {
                path: '/basic',
                layout: SliderLayout,
                component: Config,
            },
            {
                path: '/carouselList',
                layout: SliderLayout,
                component: CarouselList,
            },
        ]
    }
];

export default routerConfig;
