import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import HttpUtils from './utils/HttpUtils'
import {message, LocaleProvider} from 'antd'
import store from './store';
import {HashRouter as Router} from 'react-router-dom';
import {Provider} from 'mobx-react';
import router from './router';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
window.$http = HttpUtils
window.$message = message

const CONTAINER = document.getElementById('root');

ReactDOM.render(
  <LocaleProvider locale={zh_CN}>
    <Provider
      store={store}
    >
      <Router>{router}</Router>
    </Provider>
  </LocaleProvider>
  , CONTAINER);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
