import React, {Component} from 'react';
import {Input, Icon, Button, Statistic} from 'antd';
import moment from 'moment';
import qs from 'qs'
import HttpUtils from "../utils/HttpUtils";
import PropTypes from "prop-types";

const Countdown = Statistic.Countdown

export default class CustomLogin extends Component {

  static defaultProps = {
    smsCode: false,
    code: false
  }
  static propTypes = {
    smsCode: PropTypes.bool,
    code: PropTypes.bool,
    codeUrl:PropTypes.string,
    smsCodeUrl:PropTypes.string,
    loginUrl:PropTypes.string.isRequired,
    onPush:PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      captcha_src: '',
      captcha_code: '',
      captcha_id: '',
      code: '',
      countdown: false,
      sendLoading: false
    };

  }

  _login() {
    if (!this.state.username || !this.state.password) {
      window.$message.error('内容填写不完整')
      return
    }
    window.$message.loading('登录中')
    window.$http.postForm(this.props.loginUrl, {
      ...this.state,
      category: this.state.identify_code && this.state.identify_code.length !== 6 ? 1 : 2
    }).then(res => {
      window.$message.destroy()
      if (res.status === 10000) {
        window.$message.success('登录成功')
        this.props.onPush()
      } else {
        this.getCaptcha();
        window.$message.error(res.message)
      }
    }).catch(err => {
      window.$message.destroy()
      window.$message.error('通讯失败,请重试')
    })
  }

  componentDidMount() {
//     UserAction.getUserInfo().then(res => {
//       this.props.history.push('/home')
//     })
    this.getCaptcha();
  }

  getCaptcha() {
    HttpUtils.get('/api/common/captcha').then(res => {
      if (res.status === 10000) {
        this.setState({
          captcha_src: res.src,
          captcha_id: res.vcode_id,
        })
      }
    })
  }

  _sendAuthCode() {
    if (!this.state.username || !this.state.password) {
      window.$message.error('请填写账号和密码')
      return
    }
    this.setState({sendLoading: true})
    window.$http.postForm(this.props.smsCodeUrl, {
      username: this.state.username,
      password: this.state.password
    }).then(res => {
      this.setState({sendLoading: false})
      if (res.status === 10000 || res.status === 10009) {
        this.setState({
          countdown: true,
          time: res.limit ? moment().add(parseInt(res.limit), "s") : moment().add(1, "minute")
        })
      } else {
        this.setState({
          countdown: false
        })
        window.$message.error(res.message)
      }
    }).catch(err => {
      this.setState({sendLoading: false})
      window.$message.error('通讯失败，请检查网络')
    })
  }

  render() {

    return (
      <>
        <Input.Group>
          <Input
            placeholder="用户名"
            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
            value={this.state.username}
            onChange={(e) => this.setState({username: e.target.value})}
          />
          <Input
            style={{marginTop: 15}}
            placeholder="密码"
            type={'password'}
            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
            value={this.state.password}
            onChange={(e) => this.setState({password: e.target.value})}
          />
          {this.props.smsCode &&
          <Input.Search
            style={{marginTop: 15}}
            value={this.state.identify_code}
            onChange={(e) => this.setState({identify_code: e.target.value})}
            onSearch={() => {
              this._sendAuthCode()
            }}
            enterButton={(<Button type={'primary'} loading={this.state.sendLoading} disabled={this.state.countdown}>
              {this.state.countdown && this.state.time ?
                <Countdown
                  title=""
                  value={this.state.time}
                  valueStyle={{fontSize: 14, color: '#000'}}
                  onFinish={() => {
                    this.setState({
                      countdown: false,
                      time: null
                    })
                  }}
                  format="s秒后重新发送"
                /> :
                '发送短信验证码'
              }</Button>)}
            placeholder="短信验证码"

          />
          }
          {this.props.code &&
          <Input
            style={{marginTop: 15}}
            placeholder="验证码"
            value={this.state.code}
            onChange={(e) => this.setState({code: e.target.value})}
            addonAfter={<img
              src={this.state.captcha_src}
              style={{height: 30, cursor: 'pointer', fontSize: 12}}
              alt={'点击刷新验证码'}
              onClick={() => {
                this.getCaptcha();
              }}
            />}
          />
          }
        </Input.Group>
        <div style={{marginTop: 30}}>
          <Button onClick={() => {
            this._login()
          }} type="primary" block>登录</Button>
        </div>
      </>
    );
  }
}
