import React, {Component} from 'react';
import {Card, Row, Col, List, Modal, Icon} from 'antd';
import './Home.scss';
import moment from 'moment';

const Info = ({title, value, bordered}) => (
    <div className={'headerInfo'}>
        <span>{title}</span>
        <p>{value}</p>
        {bordered && <em/>}
    </div>
);
const styles = {
    activitiesList: {
        padding: '0 24px 8px 24px',
        username: {
            color: '#868686'
        },
        event: {
            fontWeight: 'normal'
        }
    }
}
export default class Home extends Component {


    constructor(props) {
        super(props);
        this.state = {
            item: {},
            visible: false,
        };
    }

    componentDidMount() {
        this._getTaskPreview()
    }

    _getTaskPreview() {
        window.$http.get('/api/order/preview').then(res => {
            if (res.status === 10000) {
                this.setState({
                    preview: res.data
                })
            }
        })
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}
