import React, {Component} from 'react';
import {Card, PageHeader, Switch, Button, Modal} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "./components/Filter";
import HttpUtils from "../../utils/HttpUtils";
import CustomPopconfirm from "../../components/CustomPopconfirm";
import ChangeRoomInfo from "./components/ChangeRoomInfo";
import RoomExamine from "./components/RoomExamine";
import CreateExamine from './components/CreateExamine'


export default class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: '',
        }
        this.select = [
            {
                name: '房间种类',
                type: 'selectList',
                dataIndex: 'room_category_id'
            },
            {
                name: '房间名',
                type: 'input',
                dataIndex: 'name',
                inputWidth:450
            },
            {
                name: '能否预约',
                type: 'select',
                option: [{id: 1, name: '能预约'}, {id: 2, name: '不能预约'}],
                dataIndex: 'is_reservation'
            },
            {
                name: '是否展示',
                type: 'select',
                option: [{id: 1, name: '展示'}, {id: 2, name: '不展示'}],
                dataIndex: 'status'
            },

        ];

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {
                    return moment(text).format('YYYY-MM-DD  HH:mm:ss')
                }
            },
            {
                title: '房间种类名',
                dataIndex: 'room_category.name',
                key: 'room_category.name',
                width: 120,
            },
            {
                title: '房间名',
                dataIndex: 'name',
                key: 'name',
                width: 120,
            },
            {
                title: '对应门禁房间',
                dataIndex: 'third_name',
                key: 'third_name',
                width: 100,
            },
            {
                title: '房间图片',
                dataIndex: 'photos',
                key: 'photos',
                width: 100,
                render: (text => (
                    text?<img src={`http:${text.split(',')[0]}`} style={{maxHeight: 100, maxWidth: 90}}/>:'暂无图片'
                ))
            },
            {
                title: '房间叙述',
                dataIndex: 'describe',
                key: 'describe',
                width: 150,
                render:(text) => {
                    return  <>
                        <p className={'wrap'}>{text}</p>
                    </>
                }
            },
            {
                title: '排序权重',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 100,
            },
            {
                title: '是否展示',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, item) => (
                    <Switch checked={text === 1} onChange={(value) => {
                        this.changeStatus(value, item)
                    }}/>
                )
            },
            {
                title: '能否预约',
                dataIndex: 'is_reservation',
                key: 'is_reservation',
                width: 100,
                render: (text, item) => (
                    <Switch checked={text === 1} onChange={(value) => {
                        this.changeStatus2(value, item)
                    }}/>
                )
            },
            {
                title: '操作',
                width: 100,
                render: (text, record) => (
                    <>
                        {
                            record.is_add_permission_group!=1&&<><CustomPopconfirm
                                text={'同步门禁权限组'} message={'确定同步并增加权限吗？'} url={'/api/room/sync/authority'}
                                value={{room_id: record.id}}
                                onSubmit={() => {
                                    this.custom_table.getList()
                                }}
                            /><br/></>
                        }
                        <ChangeRoomInfo item={record}  id={record.id} refreshList={()=>{
                            this.custom_table.getList();
                        }}  /><br/>
                        <RoomExamine item={record} id={record.id} /> <br/>
                        <CreateExamine  room_id={record.id}  />
                    </>
                )
            }
        ]
    }


    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    changeStatus = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/room/update', {
            ...item,
            status: value ? 1 : 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    changeStatus2 = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/room/update', {
            ...item,
            is_reservation: value ? 1 : 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    render() {
        return (
            <div style={{position:'relative'}}>

                <Card style={{marginBottom: 15}}>
                    <PageHeader title="房间列表" subTitle=""/>

                </Card>
                <FilterForm select={this.select} categoryList={this.state.CategoryList} onSubmit={this.onSubmit}
                            onReset={this.onReset}/>

                <Card bordered={false}>
                    <div style={{
                        position: 'absolute',
                        right:22,
                        top: -30,
                        zIndex: 999
                    }}>
                        <Button  type="primary"
                                 size={'large'} onClick={() => {
                            HttpUtils.postForm('/api/room/sync', {}).then(res => {
                                if (res.status === 10000) {
                                    window.$message.success('同步成功');
                                    this.custom_table.getList();
                                } else {
                                    window.$message.error(res.message);
                                }
                            }).catch(err => {
                                window.$message.error('通讯失败');
                            })
                        }}>同步门禁系统房间</Button>
                    </div>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/room/list'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
            </div>
        );
    }
}



