import React, {Component} from 'react';
import {Card, Button, Modal} from 'antd';
import CustomForm from "../../../components/CustomForm";

export default class CreateDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.content = [
            {
                name: '所属部门',
                type: 'input',
                dataIndex: 'name',
                labelCol: 8,
                required:true,
                wrapperCol: 14,
                message:'请填写所属部门'
            },
            {
                name: '是否展示',
                type: 'select',
                dataIndex: 'status',
                labelCol: 8,
                required: true,
                wrapperCol: 14,
                message: '请选择是否展示',
                option: [
                    {id: true, name: '展示'},
                    {id: false, name: '不展示'}
                ]
            },
            {
                name: '人脸库名称',
                type: 'input',
                dataIndex: 'library_name',
                labelCol: 8,
                required:true,
                wrapperCol: 14,
                message:'请填写人脸库名称'
            },
            {
                name: '人脸库描述',
                type: 'input',
                dataIndex: 'library_describe',
                labelCol: 8,
                wrapperCol: 14,
                message:'请填写人脸库描述'
            },
            {
                name: '人脸库质量',
                type: 'select',
                dataIndex: 'library_quality',
                labelCol: 8,
                wrapperCol: 14,
                message: '请选择人脸库质量',
                option: [
                    {id: 'normal', name: '普通'},
                    {id: 'hight', name: '高质量'}
                ]
            },
        ]
    }

    componentDidMount() {
    }

    onSubmit = () => {
        this.props.refreshList();
        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {

        return (
            <>
                <Button style={{
                    position: 'fixed',
                    right: 30,
                    bottom: 50
                }} type="primary" shape="circle" icon="plus"
                        size={'large'} onClick={() => {
                    this.setState({visible: true})
                }}/>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="新增部门"
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <CustomForm
                        content={this.content} onSubmit={this.onSubmit} url={'/api/dept/save'}
                        imageDataIndex={'photos'}
                        values={{library_quality:'hight'}}
                    />
                </Modal>
            </>
        );
    }
}
