import React, {Component} from 'react';
import {Modal} from 'antd';
import Form from "../../../components/CustomForm";

export default class ChangePackage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.content = [
            {
                name: '封面图',
                type: 'image',
                dataIndex: 'cover',
                required: true,
                message: '请上传封面图',
                labelCol: 7,
                wrapperCol: 14,
            },
            {
                name: '排序',
                type: 'input',
                dataIndex: 'sequence',
                labelCol: 7,
                required:true,
                wrapperCol: 14,
                message:'请排序'
            },
            // {
            //     name: '链接地址',
            //     type: 'input',
            //     dataIndex: 'link',
            //     labelCol: 7,
            //     required:true,
            //     wrapperCol: 14,
            //     message:'请填写链接地址'
            // },
            // {
            //     name: '是否App内链',
            //     type: 'select',
            //     dataIndex: 'is_app_link',
            //     labelCol: 7,
            //     required:true,
            //     wrapperCol: 14,
            //     message: '请选择是否内链',
            //     option: [
            //         {id: 1, name: '是'},
            //         {id: 2, name: '不是'}
            //     ]
            // },
            {
                name: '是否展示',
                type: 'select',
                dataIndex: 'status',
                labelCol: 7,
                required: true,
                wrapperCol: 14,
                message: '请选择是否展示',
                option: [
                    {id: 1, name: '展示'},
                    {id: 2, name: '不展示'}
                ]
            },
        ]
    }

    onSubmit = () => {
        this.props.refreshList();
        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {

        return (
            <>
                <a onClick={() => {
                    this.setState({visible: true})
                }}>修改轮播图</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="修改轮播图"
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <Form
                        content={this.content} onSubmit={this.onSubmit} url={'/api/carousel/save'}
                        imageDataIndex={'cover'}
                        values={this.props.item}
                        tags={'tag'}
                        value={{id: this.props.item.id}}
                        checkNumber={true}
                    />
                </Modal>
            </>
        );
    }
}
