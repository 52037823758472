// 菜单配置
// asideMenuConfig：侧边导航配置


const asideMenuConfig = [
    {
        icon: 'bars',
        name: '主菜单',
        path: '/home',
        children: [
            {
                name: '用户审核',
                path: '/home/thirdPartyList'
            },
            {
                name: '预定审核',
                path: '/home/appointExamineList'
            },
            {
                name: '照片审核',
                path: '/home/imageCheckList'
            },
        ]
    },
    {
        icon: 'profile',
        name: '房间',
        path: '/room',
        children: [
            {
                name: '房间分类',
                path: '/room/categoryList'
            },
            {
                name: '房间列表',
                path: '/room/list'
            },
            {
                name: '预定记录',
                path: '/room/appointList'
            },
        ]
    },
    {
        icon: 'audit',
        name: '部门',
        path: '/department',
        children: [
            {
                name: '部门列表',
                path: '/department/list'
            },
        ]
    },
    {
        icon: 'user',
        name: '用户',
        path: '/user',
        children: [

            {
                name: '用户列表',
                path: '/user/list'
            }
        ]
    },
    {
        name: '配置管理',
        path: '/config',
        icon: 'tool',
        children: [
            {
                name: '轮播图',
                path: '/config/carouselList'
            },
            {
                name: '基本配置',
                path: '/config/basic'
            }
        ]
    },


];

export default asideMenuConfig;
