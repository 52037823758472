import React, {Component} from 'react';
import {Card, Input, Form, Select, Row, Col, Upload, Modal, Icon, Button} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";
import RoomSelect from "./RoomSelect";

const {TextArea} = Input;

class CreateExamine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: -1,
            content: '',
            visible: false,
            visible2:false,
            categoryData: [],
            RoomList: [],
            start_day:0,
            start_hour:0,
            start_minute:0,
            end_day:0,
            end_hour:2,
            end_minute:0,
        };
    }


    componentDidMount() {
        this.getCategoryList()
    }

    getCategoryList() {
        HttpUtils.postForm('/api/room/category/list', {
            pers: 100
        }).then(res => {
            this.setState({
                categoryData: res.data
            })
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }



    handleCancel = () => {
        this.setState({visible: false})
        this.props.form.resetFields()
    }







    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <>
                <a onClick={() => {
                    this.setState({visible: true})
                    this.props.form.setFieldsValue({
                        end_day: 0,
                        end_hour: 2,
                        end_minute: 0,
                        start_day: 0,
                        start_hour: 0,
                        start_minute: 0
                    })
                }}>分配房间权限</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    style={{position: 'absolute', top: 100, left: 0, right: 0}}
                    title="分配房间权限"
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.handleCancel}
                    className='center'
                >
                    <Card>
                        <Form
                        >
                            <Row
                                className={'right4'}
                                gutter={[32, 8]}>
                                <Form.Item label={'开始时长'} labelCol={{span: 6}} wrapperCol={{span: 18}}>
                                    <Col span={8}>
                                        {getFieldDecorator('start_day', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input onChange={(e)=>{this.setState({start_day:e.target.value})}}  addonAfter={'天'}  autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('start_hour', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input  onChange={(e)=>{
                                                this.setState({start_hour:e.target.value})
                                            }

                                            } addonAfter={'时'}  autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('start_minute', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input onChange={(e)=>{this.setState({start_minute:e.target.value})}}  addonAfter={'分'} autoComplete="off"/>
                                        )}
                                    </Col>
                                </Form.Item>
                            </Row>
                            <Row
                                className={'right4'}
                                gutter={[32, 8]}>
                                <Form.Item label={'结束时长'} labelCol={{span: 6}} wrapperCol={{span: 18}}>
                                    <Col span={8}>
                                        {getFieldDecorator('end_day', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input onChange={(e)=>{
                                                this.setState({end_day:e.target.value})
                                            }

                                            } addonAfter={'天'}  autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('end_hour', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input onChange={(e)=>{
                                                this.setState({end_hour:e.target.value})
                                            }

                                            }  addonAfter={'时'}  autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('end_minute', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input onChange={(e)=>{
                                                this.setState({end_minute:e.target.value})
                                            }

                                            } addonAfter={'分'} autoComplete="off"/>
                                        )}
                                    </Col>
                                </Form.Item>
                            </Row>
                            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                <Button style={{marginLeft: 20}} onClick={this.handleCancel}>返回</Button>
                                {/*<Button type="primary" htmlType="submit">提交</Button>*/}
                                <RoomSelect  onClick={()=>{this.setState({visible:false})}}  user_id={this.props.id} timeObj={this.state}/>
                            </div>

                        </Form>
                    </Card>
                </Modal>
            </>
        );
    }
}

const WrappedCreateExamine = Form.create()(CreateExamine);
export default WrappedCreateExamine;
