import React, {Component} from 'react';
import {Modal} from 'antd';
import Form from "./Form";
import HttpUtils from "../../../utils/HttpUtils";

export default class ChangeUserInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            content:[
                {
                    name: '头像',
                    type: 'image',
                    dataIndex: 'cover',
                    required: true,
                    message: '请上传封面图',
                    labelCol: 7,
                    wrapperCol: 14,
                },
                {
                    name: '名字',
                    type: 'input',
                    dataIndex: 'name',
                    labelCol: 8,
                    required: true,
                    wrapperCol: 14,
                    message: '请填写名字'
                },
                {
                    name: '性别',
                    type: 'select',
                    dataIndex: 'dept_id',
                    labelCol: 8,
                    required: true,
                    wrapperCol: 14,
                    message: '请选择',
                    option: [
                        {id: 'M', name: '男'},
                        {id: 'F', name: '女'}
                    ]
                },
                {
                    name: '所属部门',
                    type: 'select',
                    dataIndex: 'gender',
                    labelCol: 8,
                    required: true,
                    wrapperCol: 14,
                    message: '请选择',
                    option: []
                },
            ]
        };

    }

    onSubmit = () => {
        this.props.refreshList();
        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    getList() {
        //  TODO 注销接口
        HttpUtils.postForm('/api/dept/list', {}).then(res => {
            if (res.status === 10000) {
                this.setState({
                    content:[
                        {
                            name: '头像',
                            type: 'image',
                            dataIndex: 'avatar',
                            required: true,
                            message: '请上传头像',
                            labelCol: 8,
                            wrapperCol: 14,
                        },
                        {
                            name: '名字',
                            type: 'input',
                            dataIndex: 'name',
                            labelCol: 8,
                            required: true,
                            wrapperCol: 14,
                            message: '请填写名字'
                        },
                        {
                            name: '性别',
                            type: 'select',
                            dataIndex: 'gender',
                            labelCol: 8,
                            required: true,
                            wrapperCol: 14,
                            message: '请选择',
                            option: [
                                {id: 'M', name: '男'},
                                {id: 'F', name: '女'}
                            ]
                        },
                        {
                            name: '所属部门',
                            type: 'select',
                            dataIndex: 'dept_id',
                            labelCol: 8,
                            required: true,
                            wrapperCol: 14,
                            message: '请选择',
                            option: res.data.length > 0  ?   [{id:0,name:'未分类'}].concat(res.data.filter(item=>{return  item.status === true}).map(item=>{
                                return {
                                    id:item.id,
                                    name:item.name
                                }
                            }))  : []
                        },
                    ]
                })
            }
        })
    }


    render() {

        return (
            <>
                <a onClick={() => {
                    this.getList()
                    this.setState({visible: true})
                }}>修改用户信息</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="修改用户信息"
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <Form
                        content={this.state.content} onSubmit={this.onSubmit} url={'/api/user/info/update'}
                        values={this.props.item}
                        value={{id: this.props.item.id}}
                        checkNumber={true}
                        imageDataIndex={'avatar'}
                    />
                </Modal>
            </>
        );
    }
}
