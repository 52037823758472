import React, {Component} from 'react';
import {Card, PageHeader, Switch} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "../../components/Filter";
import HttpUtils from "../../utils/HttpUtils";
import ChangeExamineInfo from "./components/ChangeExamineInfo";
import AddExamine from "./components/AddExamine";
import CustomPopconfirm from "../../components/CustomPopconfirm";


const select = [
    {
        name: '创建时间',
        type: 'date',
        dataIndex: ['start_time', 'end_time']
    },
    {
        name: '预定日期',
        type: 'date',
        format:'YYYY-MM-DD',
        dataIndex: ['start_date', 'end_date']
    },
    {
        name: '审核状态',
        type: 'select',
        option: [{id: 1, name: '审核中'}, {id: 2, name: '审核通过'}, {id: 3, name: '审核拒绝'}],
        dataIndex: 'status',
        wrap:4

    },
    {
        name: '手机号/姓名',
        type: 'input',
        dataIndex: 'key',
        inputWidth:350,
        seachLeft:20
    },
    {
        name: '房间名',
        type: 'input',
        dataIndex: 'roomName',
        inputWidth:350,
        seachLeft:20
    },
    {
        name: '房间种类名',
        type: 'input',
        dataIndex: 'categoryName',
        inputWidth:350,
        seachLeft:20
    }
]

export default class RoomExamineList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: ''

        }

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {
                    return moment(text).format('YYYY-MM-DD  HH:mm:ss')
                }
            },
            {
                title: '流水号',
                dataIndex: 'tx_no',
                key: 'tx_no',
                width: 200,
            },
            {
                title: '真实姓名',
                dataIndex: 'full_name',
                key: 'full_name',
                width: 100,
            },
            {
                title: '手机号',
                dataIndex: 'user.mobile_phone',
                key: 'user.mobile_phone',
                width: 120,
            },
            {
                title: '房间种类',
                dataIndex: 'room_category.name',
                key: 'room_category.name',
                width: 120,
            },
            {
                title: '房间名',
                dataIndex: 'room.name',
                key: 'room.name',
                width: 120,
            },

            {
                title: '开始时间',
                dataIndex: 'start_time',
                key: 'start_time',
                width: 150,
                render: (text, record) => {
                    if(text){
                        return record.date + ' ' + text.substr(0, 5)
                    }else {
                        return record.start_date
                    }
                }
            },
            {
                title: '结束时间',
                dataIndex: 'end_time',
                key: 'end_time',
                width: 150,
                render: (text, record) => {
                    if(text){
                        return record.date + ' ' + text.substr(0, 5)
                    }else {
                        return record.end_date
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                width: 150,
                render: (text) => {
                    return <>
                        <p className={'wrap'}>{text}</p>
                    </>
                }
            },
            {
                title: '拒绝原因',
                dataIndex: 'reason',
                key: 'reason',
                width: 130,
                render: (text) => {
                    return <>
                        <p className={'wrap'}>{text}</p>
                    </>
                }
            },
            {
                title: '审核状态',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text => {
                    switch (text) {
                        case 1 :
                            return '审核中';
                        case 2 :
                            return '审核通过';
                        case 3 :
                            return '审核拒绝';
                    }
                })
            },
            {
                title: '审核人',
                dataIndex: 'examiner_id',
                key: 'examiner_id',
                width: 100,
                render: ((text, record) => {
                    return record.status != 1 && text === 0 ? '超级管理员' : record.examiner.name
                })
            },
            {
                title: '操作',
                width: 120,

                render: (text, record) => (
                    <>
                        {
                            record.status === 1 ? <>
                                <AddExamine item={record} id={record.id} refreshList={() => {
                                    this.custom_table.getList();
                                }}/><br/>
                            </> : null
                        }
                    </>
                )
            }
        ]

    }

    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    render() {
        return (
            <div>
                <Card style={{marginBottom: 15}}>
                    <PageHeader title="预定记录" subTitle=""/>
                </Card>
                <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                <Card bordered={false}>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/room/reservation/record'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
            </div>
        );
    }
}



