import {observable, action} from "mobx";


class App {
    @observable menuSelected = ['1'];
    @observable config = {};
    @observable categories = [];
    @observable openKeys = [];
    @observable user = undefined;

    @action setUser(user) {
        this.user = user
    }

    @action setMenuSelected(key) {
        this.menuSelected = [key]
    }

    @action setConfig(conf = undefined) {
        this.config = conf
    }

    @action setOpenKeys(OpenKey) {
        this.openKeys = OpenKey
    }

    @action setCategories(categories = undefined) {
        this.categories = categories
    }
}


export default new App();
