import React, {Component} from 'react';
import {Card, PageHeader,Switch} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "../../components/Filter";
import ChangeCarousel from './components/ChangeCarousel'
import CreateCarousel from "./components/CreateCarousel";
import HttpUtils from '../../utils/HttpUtils'



const select = [
    {
        name: '是否展示',
        type: 'select',
        option: [{id: 1, name: '展示'}, {id: 2, name: '不展示'}],
        dataIndex: 'status'
    },
];

export default class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: ''

        }

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {return moment(text).format('YYYY-MM-DD  HH:mm:ss') }
            },
            {
                title: '封面图片',
                dataIndex: 'cover',
                key: 'cover',
                width: 200,
                render:(text => (
                    <div>
                        <img src={`http:${text.split(',')[0]}`} style={{maxHeight: 100, maxWidth: 90}}  />
                        {/*<img src={`http:${text.split(',')[1]}`} style={{maxHeight: 100, maxWidth: 90}}  />*/}
                    </div>
                ))
            },
            // {
            //     title: '链接地址',
            //     dataIndex: 'link',
            //     key: 'link',
            //     width: 150,
            // },
            // {
            //     title: '是否App内链',
            //     dataIndex: 'is_app_link',
            //     key: 'is_app_link',
            //     width: 100,
            //     render:(text)=>{
            //     switch (text) {
            //         case 1 : return '是' ;
            //         case 2 : return '否' ;
            //     }}
            // },
            {
                title: '排序',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 200,
            },
            {
                width: 140,
                title: '是否展示',
                dataIndex: 'status',
                key: 'status',
                render: (text, item) => (
                    <Switch checked={text === 1} onChange={(value) => {
                        this.changeStatus(value, item)
                    }}/>
                )
            },
            {
                title: '操作',
                width: 300,

                render: (text,record) => (
                    <>
                        <ChangeCarousel item={record}  id={record.id} refreshList={()=>{
                            this.custom_table.getList();
                        }}  />
                    </>
                )
            }
        ]

    }


    changeStatus = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/carousel/update', {
            ...item,
            status: value ? 1 : 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    render() {
        return (
            <div>
                <Card style={{marginBottom:15}}>
                    <PageHeader title="轮播图" subTitle="" />
                </Card>
                <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                <Card bordered={false}>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/carousel/list'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
                <CreateCarousel  refreshList={()=>{this.custom_table.getList()}}  />
            </div>
        );
    }
}



