import React, {Component} from 'react';
import {Modal, Button, Spin, Table, Card} from 'antd/lib/index';
import HttpUtils from "../../../utils/HttpUtils";
import ChangeExamineInfo from "./ChangeExamineInfo";
import CustomPopconfirm from "../../../components/CustomPopconfirm";


export default class PurchaseDialog extends Component {


    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            data: undefined,
            table_loading: false,
            values: {},
            form: {
                page: 1,
                pers: 10
            },
            dataSource: [],
            count: 0
        };
        this.columns = [
            {
                title: '真实姓名',
                dataIndex: 'user.name',
                key: 'user.name',
                width: 200,
            },
            {
                title: '手机号',
                dataIndex: 'user.mobile_phone',
                key: 'user.name',
                width: 200,
            },
            {
                title: '房间名',
                dataIndex: 'room.name',
                key: 'room.name',
                width: 200,
            },
            // {
            //     title: '房间描述',
            //     dataIndex: 'room.describe',
            //     key: 'room.describe',
            //     width: 200,
            // },
            {
                title: '开始时长',
                dataIndex: 'start_space_time',
                key: 'start_space_time',
                width: 200,
                render: (text) => {
                    if(text === 0 ){
                        return text
                    }else {
                        return this.timeStamp(text)
                    }
                }
            },
            {
                title: '结束时长',
                dataIndex: 'end_space_time',
                key: 'end_space_time',
                width: 200,
                render: (text) => {
                    if(text === 0 ){
                        return text
                    }else {
                        return this.timeStamp(text)
                    }
                }
            },
            {
                title: '操作',
                width: 300,

                render: (text,record) => (
                    <>
                        <ChangeExamineInfo item={record}  id={record.id} refreshList={()=>{
                            this.getData();
                        }}  /><br/>
                        <CustomPopconfirm
                            text={'删除房间权限'} message={'确定删除房间权限吗？'} url={'/api/room/examine/distribution/delete'}
                            value={{user_id: record.user_id,room_id:record.room_id}}
                            onSubmit={() => {
                                this.getData()
                            }}
                        />
                    </>
                )
            }
        ]
    }


    timeStamp( second_time ){

        var time = parseInt(second_time) + "秒";
        if( parseInt(second_time )> 60){

            var min = parseInt(second_time / 60);
            time = min + "分" ;

            if( min > 60 ){
                min = parseInt(second_time / 60) % 60;
                var hour = parseInt( parseInt(second_time / 60) /60 );
                if(min === 0){
                    time = hour + "小时" ;
                }else {
                    time = hour + "小时" + min + "分" ;
                }

                if( hour > 24 ){
                    hour = parseInt( parseInt(second_time / 60) /60 ) % 24;
                    var day = parseInt( parseInt( parseInt(second_time / 60) /60 ) / 24 );
                    if(min ===0 ){
                        time = day + "天" + hour + "小时" ;
                    }else {
                        time = day + "天" + hour + "小时" + min + "分" ;
                    }
                }
            }


        }

        return time;
    }

    componentDidMount() {
    }

    _show() {
        this.setState({visible: true, loading: true})
        this.getData();
    }


    getData() {
        this.setState({table_loading: true})
        HttpUtils.postForm('/api/room/examine/distribution/list', {
            key:this.props.item.mobile_phone&&this.props.item.mobile_phone,
            room_id:this.props.id && this.props.id,
            ...this.state.form,
            ...this.state.values,
        }).then(res => {
            this.setState({table_loading: false})
            if (res.status === 10000) {
                this.setState({
                    dataSource: res.data,
                    count: res.count
                })
            }
        }).catch(err => {
            window.$message.error('通讯失败')
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    onSubmit = (val) => {
        this.setState({
            form: {
                page: 1,
                pers: 1000,
            },
            values: val
        }, () => {
            this.getData()
        })
    }
    onReset = () => {
        this.setState({
            values: {},
        }, () => {
            this.getData();
        })
    }

    _changePers = (current, size) => {
        this.setState({
            form: {
                page: 1,
                pers: size
            }
        }, () => {
            this.getData();
        })
    }
    _getCurrent = (page) => {
        this.setState({
            form: {
                page: page,
                pers: this.state.form.pers
            }
        }, () => {
            this.getData()
        })
    }

    render() {
        return (
            <>
                <a  type="default" size={'small'} onClick={() => {
                    this._show()
                }}>房间权限调整</a>
                <Modal
                    title={'房间权限调整'}
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    width={window.innerWidth * 0.8}
                    onCancel={this.handleCancel}
                >
                    <Card bordered={false}>
                        <Table
                            scroll={{x: 1700}}
                            loading={this.state.table_loading}
                            dataSource={this.state.dataSource}
                            columns={this.columns}
                            pagination={{
                                showTotal: (total) => {
                                    return `总共${total}条`
                                },
                                showSizeChanger: true,
                                onShowSizeChange: this._changePers,
                                pageSizeOptions: ['10', '30', '50', '100'],
                                showQuickJumper: true,
                                current: this.state.form.page,
                                total: this.state.count,
                                onChange: this._getCurrent,
                                defaultPageSize: this.state.form.pers
                            }}
                        />
                    </Card>
                </Modal>
            </>
        );
    }
}
