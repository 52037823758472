import React, {Component} from 'react';
import {Modal,Button} from 'antd';
import Form from "./Form2";
import HttpUtils from "../../../utils/HttpUtils";

export default class AddExamine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            content1: [
                {
                    name: '名字',
                    type: 'input',
                    dataIndex: 'name',
                    labelCol: 8,
                    required: true,
                    wrapperCol: 14,
                    message: '请填写名字'
                },
                {
                    name: '性别',
                    type: 'select',
                    dataIndex: 'gender',
                    labelCol: 8,
                    required: true,
                    wrapperCol: 14,
                    message: '请选择',
                    option: [
                        {id: 'M', name: '男'},
                        {id: 'F', name: '女'}
                    ]
                },
                {
                    name: '所属部门',
                    type: 'select',
                    dataIndex: 'dept_id',
                    labelCol: 8,
                    required: true,
                    wrapperCol: 14,
                    message: '请选择',
                    option: []
                },
                {
                    name: '审核结果',
                    type: 'select',
                    dataIndex: 'status',
                    required: true,
                    labelCol: 8,
                    wrapperCol: 14,
                    message: '请选择',
                    option: [
                        {id: 2, name: '通过'},
                        {id: 3, name: '拒绝'}
                    ]
                },
                {
                    name: '拒绝原因',
                    type: 'textarea',
                    dataIndex: 'reason',
                    placeholder: '拒绝必填',
                    labelCol: 8,
                    wrapperCol: 16,
                    message: '请填写拒绝原因'
                },
            ],
            content2:[
                {
                    name: '审核结果',
                    type: 'select',
                    dataIndex: 'status',
                    labelCol: 5,
                    required: true,
                    wrapperCol: 14,
                    message: '请选择审核结果',
                    option: [
                        {id: 2, name: '允许绑定'},
                        {id: 3, name: '拒绝绑定'}
                    ]
                },
                {
                    name: '所属部门',
                    type: 'select',
                    dataIndex: 'dept_id',
                    labelCol: 5,
                    required: true,
                    wrapperCol: 14,
                    message: '请选择',
                    option: []
                },
                {
                    name: '拒绝原因',
                    type: 'textarea',
                    dataIndex: 'reason',
                    placeholder:'拒绝必填',
                    labelCol: 5,
                    wrapperCol: 16,
                    message:'请填写拒绝原因'
                },
            ],
            visible1:false,
            visible2:false
        };
    }

    componentDidMount() {
    }


    getList1() {
        HttpUtils.postForm('/api/dept/list', {}).then(res => {
            if (res.status === 10000) {
                this.setState({
                    content1:[
                        {
                            name: '名字',
                            type: 'input',
                            dataIndex: 'name',
                            labelCol: 8,
                            required: true,
                            wrapperCol: 14,
                            message: '请填写名字'
                        },
                        {
                            name: '性别',
                            type: 'select',
                            dataIndex: 'gender',
                            labelCol: 8,
                            required: true,
                            wrapperCol: 14,
                            message: '请选择',
                            option: [
                                {id: 'M', name: '男'},
                                {id: 'F', name: '女'}
                            ]
                        },
                        {
                            name: '所属部门',
                            type: 'select',
                            dataIndex: 'dept_id',
                            labelCol: 8,
                            required: true,
                            tip:'此部门和人脸识别权限挂钩，请认真核实',
                            wrapperCol: 14,
                            message: '请选择',
                            option: res.data.length > 0  ?   res.data.filter(item=>{return  item.status === true}).map(item=>{
                                return {
                                    id:item.id,
                                    name:item.name
                                }
                            }) : []
                        },
                        {
                            name: '审核结果',
                            type: 'select',
                            dataIndex: 'status',
                            required: true,
                            labelCol: 8,
                            wrapperCol: 14,
                            message: '请选择',
                            option: [
                                {id: 2, name: '通过'},
                                {id: 3, name: '拒绝'}
                            ]
                        },
                        {
                            name: '拒绝原因',
                            type: 'textarea',
                            dataIndex: 'reason',
                            placeholder: '拒绝必填',
                            labelCol: 8,
                            wrapperCol: 16,
                            message: '请填写拒绝原因'
                        },
                    ],
                },function () {
                    this.setState({visible1: true})
                })
            }
        })
    }


    getList2() {
        HttpUtils.postForm('/api/dept/list', {}).then(res => {
            if (res.status === 10000) {
                this.setState({
                    content2:[
                        {
                            name: '审核结果',
                            type: 'select',
                            dataIndex: 'status',
                            labelCol: 5,
                            required: true,
                            wrapperCol: 14,
                            message: '请选择审核结果',
                            option: [
                                {id: 2, name: '允许绑定'},
                                {id: 3, name: '拒绝绑定'}
                            ]
                        },
                        {
                            name: '所属部门',
                            type: 'select',
                            dataIndex: 'dept_id',
                            labelCol: 5,
                            required: true,
                            wrapperCol: 14,
                            message: '请选择',
                            tip:'此部门和人脸识别权限挂钩，请认真核实',
                            option: res.data.length > 0  ?   res.data.filter(item=>{return  item.status === true}).map(item=>{
                                return {
                                    id:item.id,
                                    name:item.name
                                }
                            }) : []
                        },
                        {
                            name: '拒绝原因',
                            type: 'textarea',
                            dataIndex: 'reason',
                            placeholder:'拒绝必填',
                            labelCol: 5,
                            wrapperCol: 16,
                            message:'请填写拒绝原因'
                        },
                    ],
                },function () {
                    this.setState({visible2: true})
                })
            }
        })
    }



    onSubmit = () => {
        this.props.refreshList();
        this.setState({
            visible1: false,
            visible2: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible1: false,
            visible2: false
        })
    }

    render() {

        return (
            <>
                <a  onClick={() => {

                    HttpUtils.postForm('/api/user/check/mobile', {
                        id:this.props.item.user.id
                    }).then(res => {
                        if (res.status === 10000) {
                            if(res.data===2){
                                this.getList1()
                            }else {
                                this.getList2()
                            }
                        }

                    }).catch(err => {
                        window.$message.error('通讯失败')
                    })
                    this.setState({visible: true})
                }}>审核</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="审核"
                    visible={this.state.visible2}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <Form
                        content={this.state.content2} onSubmit={this.onSubmit} url={'/api/user/bind/examine'}
                        values={this.props.item}
                        value={{id:this.props.item.id}}
                        thirdBind={true}
                    />
                </Modal>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="新增第三方系统账户并绑定本系统账户"
                    visible={this.state.visible1}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <Form
                        content={this.state.content1} onSubmit={this.onSubmit} url={'/api/user/addAndBind'}
                        values={Object.assign(this.props.item.user,{'dept_id':this.props.item.dept_id})}
                        value={{id:this.props.item.id}}
                        thirdBind={true}
                    />
                </Modal>
            </>
        );
    }
}
