import React, {Component} from 'react';
import {Card, Input, Form, Select, Row, Col, Upload, Modal, Icon, Button} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";
import ImgCrop from "antd-img-crop";

const {TextArea} = Input;

class CreateExamine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: -1,
            content: '',
            visible: false,
            categoryData: [],
            RoomList: [],
            fileList: [],
        };
    }


    componentDidMount() {
        this.getCategoryList()

    }

    getCategoryList() {
        HttpUtils.postForm('/api/room/category/list', {
            pers: 100
        }).then(res => {
            this.setState({
                categoryData: res.data
            })
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    getRoomList(room_category_id) {
        HttpUtils.postForm('/api/room/list', {
            room_category_id
        }).then(res => {
            this.setState({
                RoomList: res.data
            })
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    handleCancel = () => {
        this.setState({visible: false,previewVisible:false})
        this.props.form.resetFields()
    }

    handleCancelPreview = () => {
        this.setState({previewVisible:false})
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            window.$http.postForm('/api/room/update', {
                ...values,
                id: this.props.item.id
            }).then(res => {
                if (res.status === 10000) {
                    window.$message.success('提交成功！');
                    this.props.refreshList();
                    this.setState({
                        visible: false
                    })
                } else {
                    window.$message.error(res.message);
                }
                this.props.form.resetFields()
            }).catch((err) => {
                window.$message.error('通讯失败');
            })
        });
    }
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleChange = (fileList, key) => {
        this.setState({fileList});
        if (fileList.length === 0) {
            this.props.form.setFieldsValue({[key]: ''})
        } else if (fileList[0].response) {
            this.props.form.setFieldsValue({[key]: fileList[0].response.url})
        }
    }


    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        console.log(e);
        return e && e.fileList;
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {previewVisible, previewImage, fileList} = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (
            <>
                <a onClick={() => {
                    this.props.form.setFieldsValue({
                        ...this.props.item,

                    })
                    this.setState({
                        fileList: [{
                            uid: '-1',
                            name: 'xxx.png',
                            status: 'done',
                            url: `https:${this.props.item['photos']}`
                        }],
                        visible: true})
                }}>修改房间信息</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    style={{position: 'absolute', top: 30, left: 0, right: 0}}
                    title="修改房间信息"
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.handleCancel}
                    className='center'
                >
                    <Card>
                        <Form
                            onSubmit={this.handleSubmit}
                        >
                            <Form.Item label={'房间名'} labelCol={{span: 7}} wrapperCol={{span: 12}}>
                                {getFieldDecorator('name', {
                                    rules: [{required: true, message: '必须填写'}]
                                })(
                                    <Input autoComplete="off"/>
                                )}
                            </Form.Item>
                            <Form.Item label={'房间内容介绍图'} labelCol={{span: 7}} wrapperCol={{span: 12}}>
                                {getFieldDecorator('photos', {
                                    valuePropName: 'fileList',
                                    getValueFromEvent: this.normFile,
                                })(
                                    <div style={{width: 100, height: 100}}>
                                        <ImgCrop
                                            resize={true}
                                            width={1500}
                                            height={800}
                                            // contain={true}
                                        >
                                            <Upload
                                                action="/api/file/file/upload"
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                onChange={({fileList}) => {
                                                    this.handleChange(fileList,'photos')
                                                }}
                                            >
                                                {fileList && fileList.length >= 1 ? null : uploadButton}
                                            </Upload>
                                        </ImgCrop>

                                        <Modal visible={previewVisible} footer={null}
                                               onCancel={this.handleCancelPreview}>
                                            <img alt="上传的图片" style={{width: '100%'}} src={previewImage}/>
                                        </Modal>
                                    </div>
                                )}
                            </Form.Item>
                            <Form.Item label={'是否展示'} labelCol={{span: 7}} wrapperCol={{span: 12}}>
                                {getFieldDecorator('status', {
                                    rules: [{required: true, message: '必须填写'}],
                                })(
                                    <Select
                                        placeholder="请选择"
                                        name={'status'}
                                        style={{width: 120}}
                                        // value={this.props.values[item.dataIndex]}

                                    >
                                        <Select.Option key={1} value={1}>展示</Select.Option>
                                        <Select.Option key={2} value={2}>不展示</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label={'房间种类'} labelCol={{span: 7}} wrapperCol={{span: 12}}>
                                {getFieldDecorator('room_category_id', {
                                    rules: [{required: true, message: '必须填写'}],
                                })(
                                    <Select
                                        onChange={(id) => this.getRoomList(id)}
                                    >
                                        {this.state.categoryData.map(item => {
                                            return (
                                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label={'房间描述'} labelCol={{span: 7}} wrapperCol={{span: 12}}>
                                {getFieldDecorator('describe', {
                                    rules: [{required: true, message: '必须填写'}]
                                })(
                                    <Input.TextArea
                                        placeholder={"请输入"}
                                        name={'describe'}
                                        style={{height: 120, resize: 'none'}}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label={'排序'} labelCol={{span: 7}} wrapperCol={{span: 12}}>
                                {getFieldDecorator('sequence', {
                                    rules: [{required: true, message: '必须填写'}]
                                })(
                                    <Input autoComplete="off"/>
                                )}
                            </Form.Item>
                            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                <Button style={{marginLeft: 20}} onClick={this.handleCancel}>返回</Button>
                                <Button type="primary" htmlType="submit">提交</Button>
                            </div>

                        </Form>
                    </Card>
                </Modal>
            </>
        );
    }
}

const WrappedCreateExamine = Form.create()(CreateExamine);
export default WrappedCreateExamine;
