import qs from 'qs'
import axios from 'axios'

export default class HttpUtils {

    static get(url, timeout) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                timeout: timeout ? timeout : 30000
            })
                .then(result => {
                    resolve(result.data);
                })
                .catch(error => {
                    reject(error);
                    if (url !== '/api/user/userinfo') {
                        if (error.response && error.response.status === 403) {
                            window.location.href = '/admin';
                        }
                    }

                })
        })
    }


    static post(url, data) {
        return new Promise((resolve, reject) => {
            axios.post(url, data, {
                timeout: 30000
            })
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => {
                    reject(error)
                    if (url !== '/api/user/userinfo') {
                        if (error.response && error.response.status === 403) {
                            window.location.href = '/';
                        }
                    }
                })
        })
    }

    static postForm(url, data, timeout) {
        return new Promise((resolve, reject) => {
            axios.post(url, qs.stringify(data), {
                timeout: timeout ? timeout : 30000
            })
                .then(result => {
                    resolve(result.data);
                })
                .catch(error => {
                    reject(error);
                    if (window.location.pathname !== '/admin' && error.response && error.response.status === 403) {
                        window.location.href = '/'
                    }
                })
        })

    }
}
