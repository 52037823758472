import React, {Component} from 'react';
import {Card, PageHeader, Switch} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "../../components/Filter";
import ChangeCategory from './components/ChangeCategory'
import CreateCategory from './components/CreateCategory'
import HttpUtils from "../../utils/HttpUtils";


const select = [
    {
        name: '是否展示',
        type: 'select',
        option: [{id: 1, name: '展示'}, {id: 2, name: '不展示'}],
        dataIndex: 'status'
    },
]

export default class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: false,
            value: ''

        }

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {
                    return moment(text).format('YYYY-MM-DD  HH:mm:ss')
                }
            },
            {
                title: '房间种类名',
                dataIndex: 'name',
                key: 'name',
                width: 200,
            },
            {
                title: '房间种类图片',
                dataIndex: 'photos',
                key: 'photos',
                width: 200,
                render: (text => (
                    <img src={`http:${text.split(',')[0]}`} style={{maxHeight: 100, maxWidth: 90}}/>
                ))
                // <img src={`http://zhangyazi.oss-cn-hangzhou.aliyuncs.com/file/${text.split(',')[0]}png`} style={{maxHeight: 100, maxWidth: 90}}  />
            },
            {
                title: '房间种类叙述',
                dataIndex: 'describe',
                key: 'describe',
                width: 200,
            },
            {
                title: '排序',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 200,
            },
            {
                title: '是否展示',
                dataIndex: 'status',
                key: 'status',
                width: 200,
                render: (text, item) => (
                    <Switch checked={text === 1} onChange={(value) => {
                        this.changeStatus(value, item)
                    }}/>
                )
            },
            {
                title: '操作',
                width: 300,

                render: (text, record) => (
                    <>
                        <ChangeCategory item={record} id={record.id} refreshList={() => {
                            this.custom_table.getList();
                        }}/>
                    </>
                )
            }
        ]

    }

    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    }
    onReset = () => {
        this.custom_table.onReset();
    }


    changeStatus = (value, item) => {
        const loading = window.$message.loading('发送中', 0)
        HttpUtils.postForm('/api/room/category/update', {
            ...item,
            status: value ? 1 : 2
        }).then(res => {
            loading();
            if (res.status === 10000) {
                window.$message.success('修改成功');
                this.custom_table.getList();
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            loading();
            window.$message.error('通讯失败');
        })
    }

    render() {
        return (
            <div>
                <Card style={{marginBottom: 15}}>
                    <PageHeader title="房间分类" subTitle=""/>
                </Card>
                <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                <Card bordered={false}>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/room/category/list'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
                <CreateCategory  refreshList={()=>{this.custom_table.getList()}}  />
            </div>
        );
    }
}



