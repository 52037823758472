import React, {Component} from 'react';
import {Card, Button, Modal} from 'antd';
import CustomForm from "../../../components/CustomForm";

export default class CreateCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.content = [
            {
                name: '封面图',
                type: 'image',
                dataIndex: 'cover',
                required: true,
                message: '请上传封面图',
                labelCol: 7,
                wrapperCol: 14,
            },
            {
                name: '排序',
                type: 'input',
                dataIndex: 'sequence',
                labelCol: 7,
                required:true,
                wrapperCol: 14,
                message:'请排序'
            },
            {
                name: '是否展示',
                type: 'select',
                dataIndex: 'status',
                labelCol: 7,
                required: true,
                wrapperCol: 14,
                message: '请选择是否展示',
                option: [
                    {id: 1, name: '展示'},
                    {id: 2, name: '不展示'}
                ]
            },
        ]
    }

    componentDidMount() {
    }

    onSubmit = () => {
        this.props.refreshList();
        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {

        return (
            <>
                <Button style={{
                    position: 'fixed',
                    right: 30,
                    bottom: 50
                }} type="primary" shape="circle" icon="plus"
                        size={'large'} onClick={() => {
                    this.setState({visible: true})
                }}/>
                <Modal
                    // width={window.innerWidth * 0.8}
                    title="新增轮播图"
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >
                    <CustomForm
                        content={this.content} onSubmit={this.onSubmit} url={'/api/carousel/save'}
                        imageDataIndex={'cover'}
                        values={this.props.item}
                        tags={'tag'}
                    />
                </Modal>
            </>
        );
    }
}
