import React, {Component} from 'react';
import {inject, observer} from 'mobx-react'
import './Layout.css';
import {Icon, Menu, Layout, Modal, Input, Button} from "antd";
import {Link} from 'react-router-dom';
import asideMenuConfig from "../../menuConfig";
import HttpUtils from "../../utils/HttpUtils";
import AppAction from "../../actions/AppActions";
// import UserAction from '../../action/UserAction';

const {Header, Content, Footer, Sider} = Layout;

const SubMenu = Menu.SubMenu;

export default @inject('store')

@observer
class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            categories: [],
            keys: this.props.store.App.menuSelected,
            openKeys: this.props.store.App.openKeys,
            visible: false,
            username: '',
            password: '',
            newPasswork: '',
            captcha_id: '',//验证码id
            captcha_code: '', //验证码
            user_agent: ''
        }
    }

    onCollapse = (collapsed) => {
        console.log(collapsed);
        this.setState({collapsed});
    }
    getOpenKeys = () => {
        const {match} = this.props;
        const matched = match.path;
        let openKeys = [];

        Array.isArray(asideMenuConfig) &&
        asideMenuConfig.forEach((item, index) => {
            if (matched.startsWith(item.path)) {
                openKeys = [`${index}`];
            }
        });

        return openKeys;
    };


    componentDidMount() {
        this.getCaptcha();
    }


    //修改密码
    changePassword() {
        HttpUtils.postForm('/api/auth/update/password', {
            username: this.state.username,
            password: this.state.password,
            update_password: this.state.newPasswork,
            captcha_id: this.state.captcha_id,
            code: this.state.captcha_code
        }).then(res => {
            if (res.status === 10000) {
                window.$message.success('密码修改成功');
                this.setState({
                    visible: false
                })
            } else {
                window.$message.error(res.message)
                this.getCaptcha()
            }
        }).catch(error => {
            window.$message.error(error);
        })
    }

    //获取验证码
    getCaptcha() {
        HttpUtils.get('/api/common/captcha').then(res => {
            if (res.status === 10000) {
                this.setState({
                    captcha_src: res.src,
                    captcha_id: res.vcode_id,
                })
            }
        })
    }

    _logout() {
        //  TODO 注销接口
        HttpUtils.postForm('/api/auth/logout', {}).then(res => {
            if (res.status === 10000) {
                window.$message.success('注销成功');
                this.props.history.push('/');
            }
        })
    }

    render() {
        const {location} = this.props;
        const {pathname} = location;
        const {visible} = this.state;
        return (
            <Layout style={{minHeight: '100vh'}} hasSider>
                <Sider
                    id={'slider-layout-side'}
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                >
                    <div className="logo">

                       {/*TODO 后台logo标志(42*42左右大小)*/}
                        <img className="logo-image" src={require('../../assets/image/picture1.png')} width={60} height={55}/>
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={[pathname]}
                        defaultOpenKeys={[`${this.getOpenKeys()}`]}
                        defaultSelectedKeys={[pathname]}
                    >
                        {asideMenuConfig &&
                        asideMenuConfig.length > 0 &&
                        asideMenuConfig.map((nav, index) => {
                            if (nav.children && nav.children.length > 0) {
                                return (
                                    <SubMenu
                                        key={index}
                                        title={<span><Icon type={nav.icon}/><span>{nav.name}</span></span>}
                                    >
                                        {nav.children.map((item) => {
                                            const linkProps = {};
                                            if (item.newWindow) {
                                                linkProps.href = item.path;
                                                linkProps.target = '_blank';
                                            } else if (item.external) {
                                                linkProps.href = item.path;
                                            } else {
                                                linkProps.to = item.path;
                                            }
                                            return (
                                                <Menu.Item key={item.path}>
                                                    <Link {...linkProps}>{item.name}</Link>
                                                </Menu.Item>
                                            );
                                        })}
                                    </SubMenu>
                                );
                            }
                            const linkProps = {};
                            if (nav.newWindow) {
                                linkProps.href = nav.path;
                                linkProps.target = '_blank';
                            } else if (nav.external) {
                                linkProps.href = nav.path;
                            } else {
                                linkProps.to = nav.path;
                            }
                            return (
                                <Menu.Item key={nav.path}>
                                    <Link {...linkProps}>
                                        <>
                                            {nav.icon ? (
                                                <Icon type={nav.icon}/>
                                            ) : null}
                                            <span className="ice-menu-collapse-hide">
                            {nav.name}
                          </span>
                                        </>
                                    </Link>
                                </Menu.Item>)
                        })}
                    </Menu>
                </Sider>
                <Layout>
                    <Header className="slider-layout-header" style={{background: '#fff', padding: 0}}>

                        <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            height: '100%',
                            padding: '0 40px'
                        }}>
                            <span style={{marginRight: 10}}>
                                {this.props.store.App.user ? this.props.store.App.user.Username : ''}
                            </span>
                            <a style={{marginLeft: 10}} onClick={() => {
                                this.setState({
                                    visible: true
                                })
                            }}>修改密码</a>
                            <a style={{marginLeft: 10}} onClick={() => {
                                this._logout()
                            }}>注销</a>
                        </div>
                    </Header>
                    <Content style={{margin: '16px 16px'}}>
                        {this.props.children}
                    </Content>
                    <Footer style={{textAlign: 'center'}}>
                        easyspark ©2018
                    </Footer>
                    <Modal
                        style={{position: 'absolute', top: 300, right: 0, left: 0, margin: '300 auto'}}
                        width={window.innerWidth * 0.15}
                        title="密码修改"
                        visible={visible}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={() => {
                            this.setState({visible: false})
                        }}
                    >
                        <Input.Group>
                            <Input
                                placeholder="用户名"
                                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                value={this.state.username}
                                onChange={(e) => this.setState({username: e.target.value})}
                            />
                            <Input
                                style={{marginTop: 15}}
                                placeholder="密码"
                                type={'password'}
                                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                            <Input
                                style={{marginTop: 15}}
                                placeholder="新密码"
                                type={'password'}
                                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                value={this.state.newPasswork}
                                onChange={(e) => this.setState({newPasswork: e.target.value})}
                            />
                            <Input
                                style={{marginTop: 15}}
                                placeholder="请再次输入新密码"
                                type={'password'}
                                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                value={this.state.newPasswork2}
                                onChange={(e) => this.setState({newPasswork2: e.target.value})}
                            />
                            <Input
                                style={{marginTop: 15}}
                                placeholder="验证码"
                                value={this.state.captcha_code}
                                onChange={(e) => this.setState({captcha_code: e.target.value})}
                                addonAfter={<img
                                    src={this.state.captcha_src}
                                    style={{height: 30, cursor: 'pointer', fontSize: 12}}
                                    alt={'点击刷新验证码'}
                                    onClick={() => {
                                        this.getCaptcha();
                                    }}
                                />}
                            />
                        </Input.Group>
                        <div style={{
                            marginTop: 60,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            position: 'relative'
                        }}>
                            <Button
                                style={{position: 'absolute', left: 0, bottom: 0}}
                                onClick={() => {
                                    this.changePassword()
                                }} type="primary">确定</Button>
                            <Button
                                style={{position: 'absolute', right: 0, bottom: 0}}
                                onClick={() => {
                                    this.setState({
                                        visible: false
                                    })
                                }}>取消</Button>
                        </div>
                    </Modal>
                </Layout>
            </Layout>
        );
    }
}
