import React, {Component} from 'react';
import {Modal, Button, Spin, Table, Card, Popconfirm} from 'antd/lib/index';
import HttpUtils from "../../../utils/HttpUtils";
import ChangeExamineInfo from "../../Room/components/ChangeExamineInfo";
import CustomPopconfirm from "../../../components/CustomPopconfirm";

const { Meta } = Card;
export default class LookImages2 extends Component {


    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            data: [],
            table_loading: false,
            values: {},
            form: {
                page: 1,
                pers: 10
            },
        };
    }




    componentDidMount() {
    }

    _show() {
        this.setState({visible: true, loading: true})
        this.getData();
    }


    getData() {
        this.setState({table_loading: true})
        HttpUtils.postForm('/api/face/image/list', {
            user_id:this.props.id,
            category:2
        }).then(res => {
            this.setState({table_loading: false})
            if (res.status === 10000) {
                this.setState({
                    data: res.data,
                })
            }
        }).catch(err => {
            window.$message.error('通讯失败')
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    onSubmit = (val) => {
        this.setState({
            form: {
                page: 1,
                pers: 1000,
            },
            values: val
        }, () => {
            this.getData()
        })
    }
    onReset = () => {
        this.setState({
            values: {},
        }, () => {
            this.getData();
        })
    }




    render() {
        const  {data} = this.state;
        return (
            <>
                <a  type="default" size={'small'} onClick={() => {
                    this._show()
                }}>查看身份证照片</a>
                <Modal
                    title={'查看身份证照片'}
                    visible={this.state.visible}
                    footer={null}
                    destroyOnClose={true}
                    width={570}
                    onCancel={this.handleCancel}
                >
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',}}>
                        {
                            data.length > 0 ? data.map(item=>(
                                <Card
                                    hoverable
                                    style={{ width: 242 ,marginRight:40,height:330}}
                                    cover={<img alt="example" style={{height:240,width:240}} src={item.src} />}
                                    key={item.id}
                                >
                                    <Meta style={{textAlign:'center'}} title={item.tag == 'faced_id_card'?'带头像一面':'带国徽一面'}  />
                                    <Popconfirm
                                        title="确定要删除吗？"
                                        okText="确定"
                                        cancelText="取消"
                                        onConfirm={() => {
                                            window.$http.postForm('/api/face/image/delete', {id: item.id}).then(res => {
                                                if (res.status === 10000) {
                                                    window.$message.success('删除成功');
                                                    this.getData()
                                                    this.props.refreshList()
                                                } else {
                                                    window.$message.error(res.message);
                                                }
                                            })
                                        }}
                                    >
                                        <div style={{position:'absolute',left:'50%',transform:'translateX(-50%)',bottom:5}}>
                                            <Button   type="danger"
                                                      size={'default'} onClick={() => {

                                            }}>删除</Button>
                                        </div>
                                    </Popconfirm>
                                </Card>
                            )) : <div style={{fontSize:14,color:'rgba(0,0,0,.25)',height:60,margin:'0 auto'}}>暂无照片</div>
                        }
                    </div>
                </Modal>
            </>
        );
    }
}
