import React, {Component} from 'react';
import {Table, Input, Button} from 'antd';
import HttpUtils from "../utils/HttpUtils";
import PropTypes from 'prop-types';
import ConfirmPop from "../pages/Room/components/ConfirmPop";


export default class CustomTable extends Component {

    static defaultProps = {
        pagination: true,
        scroll: undefined,
        checked_box: false
    }

    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            width: PropTypes.number,
            title: PropTypes.string,
            dataIndex: PropTypes.string,
            key: PropTypes.string,
            render: PropTypes.func
        })).isRequired,
        url: PropTypes.string.isRequired,
        scroll: PropTypes.object,
        checked_box: PropTypes.bool,
        checkConfirm: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            visible: false,
            selectedRowKeys: []

        };
    }

    componentDidMount() {
        this.getList()
    }

    onSubmit = (value) => {
        this.setState({
            form: {
                page: 1,
                pers: this.state.form.pers,
            },
            values: value
        }, () => {
            this.getList()
        })
    }
    onReset = () => {
        this.setState({
            values: {}
        }, () => {
            this.getList();
        })
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    getList() {
        this.setState({loading: true})
        HttpUtils.postForm(this.props.url, {
            ...this.state.form,
            order: 'id desc',
            ...this.props.value,
            ...this.state.values
        }).then(res => {
            this.setState({loading: false})
            if (res.status === 10000) {
                this.setState({
                    dataSource: res.data,
                    count: res.count,
                })
            }
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    _getCurrent = (page) => {
        this.setState({
            form: {
                page: page,
                pers: this.state.form.pers
            }
        }, () => {
            this.getList()
        })
    }

    _changePers = (current, size) => {
        this.setState({
            form: {
                page: 1,
                pers: size
            }
        }, () => {
            this.getList();
        })
    }

    render() {
        const {scroll, columns, pagination, checked_box} = this.props;
        const {loading, dataSource, count, form, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                {checked_box &&
                <div style={{marginBottom:10}}>

                    <ConfirmPop
                        disabled={!hasSelected} loading={loading}
                        onConfirm={()=>{
                            this.props.checkConfirm && this.props.checkConfirm(selectedRowKeys);
                        }}
                        text={this.props.title && this.props.title}
                        message={`确定要${this.props.title && this.props.title}吗？`}
                        onSubmit={() => {
                            this.custom_table.getList()
                        }}
                    >
                    </ConfirmPop>
                    {hasSelected ? `已选择 ${selectedRowKeys.length} 条记录` : ''}
                </div>
                }

                <Table
                    rowKey={'id'}
                    rowSelection={checked_box ? rowSelection : null}
                    scroll={scroll}
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination ? {
                        showTotal: (total) => {
                            return <div style={{display: 'flex'}}>
                                <div style={{paddingLeft: 18}}>总共{total}条</div>
                            </div>
                        },
                        showSizeChanger: true,
                        onShowSizeChange: this._changePers,
                        pageSizeOptions: ['10', '30', '50', '100'],
                        showQuickJumper: true,
                        current: form.page,
                        total: count,
                        onChange: this._getCurrent
                    } : null}
                />
            </div>
        );
    }
}
