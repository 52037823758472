import React, {Component} from 'react';
import {Popconfirm} from 'antd';
import HttpUtils from "../utils/HttpUtils";
import PropTypes from 'prop-types';


export default class CustomPopconfirm extends Component {

    static defaultProps = {}

    static propTypes = {
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        value: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    onSubmit = () => {
        window.$message.loading('加载中',0);
        HttpUtils.postForm(this.props.url, {
            ...this.props.value,
        }).then(res => {
            window.$message.destroy();
            if (res.status === 10000) {
                window.$message.success('提交成功');
                this.props.onSubmit && this.props.onSubmit()
            } else {
                window.$message.error(res.message);
            }
        }).catch(err => {
            window.$message.destroy();
            window.$message.error('通讯失败')
        })
    }

    render() {
        return (
            <Popconfirm title={this.props.message} onConfirm={() => this.onSubmit()}>
                <a href="javascript:;" style={{marginRight: 10,color:this.props.color}}>{this.props.text}</a>
            </Popconfirm>
        );
    }
}
