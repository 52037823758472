export default class Precision {

    static getPrecision(value, precision,toastBool = true) {
        if (value === 0) {
            return 0;
        }
        if (value === '') {
            return ''
        }
        if (value && isNaN(value)) {
            if(toastBool){
                window.$message.warning('请输入数字格式');
            }
            return null;
        }
        if (String(value).indexOf('e') !== -1) {
            if(toastBool) {
                window.$message.warning(`已超出精度范围`);
            }
            return null;
        }
        let temp = String(value).split('.');
        if (temp[1] && temp[1].length > precision) {
            if (precision === 0) {
                if(toastBool){
                window.$message.warning(`必须为整数`);
                }
            } else {
                if(toastBool) {
                    window.$message.warning(`精度最多${precision}位，已超出精度范围`);
                }
            }
            return null;
        }
        if (temp[1]) {
            return parseInt(temp.join('')) * Math.pow(10, precision - temp[1].length);
        }
        return parseInt(temp.join('')) * Math.pow(10, precision);
    }

}
