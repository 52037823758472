import React, {Component} from 'react';
import {Card, Row, Col, Switch, Modal, InputNumber, Button, TimePicker, Input} from 'antd';
import Precision from "../../utils/Precision";
import moment from 'moment';
import './config.scss'

const group = [
    '系统配置',
    '房间配置',
];

export default class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: [],
            item: {},
            visible: false,
            disabled: false,
            switchs: []
        }
    }


    componentDidMount() {
        this._getList()
    }

    _getList() {
        window.$http.postForm('/api/system/config/list', {}).then(res => {
            if (res.status === 10000) {
                this.setState({
                    config: res.data,
                    switchs: res.auto_quantity_switch_map
                })
            }
        })
    }

    _renderType = (group, item, i) => {
        if ((/.*switch$/).test(item.key)) {
            if (group.indexOf(item.name.slice(0, item.name.length - 2)) !== -1) {
                return null;
            } else {
                return (
                    <div key={i}
                         style={{
                             display: 'flex',
                             justifyContent: 'space-between',
                             marginBottom: 14,
                             lineHeight: '32px',
                             alignItems: 'center'
                         }}>
                        <div style={{marginRight: 5}}>{item.name}</div>
                        <Switch onChange={(value) => {
                            this.updateConfig(item.group, item.key, value ? 1 : 2)
                        }} checkedChildren="启" unCheckedChildren="关"
                                checked={item.value == 1 ? true : false}/>
                    </div>
                )

            }
        }
        if ((/.*time$/).test(item.key)) {
            return (
                <div key={i}
                     style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                    <div style={{flex: 4}}>{item.name}</div>
                    <span style={{flex: 2}}>{item.value}</span>
                    <Button type="primary" shape="circle" icon="edit" onClick={() => {
                        this.setState({visible: true, item: item, value: item.value})
                    }}/>
                </div>
            )
        }
        if ((/.*ticker$/).test(item.key)) {
            return (
                <div key={i}
                     style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                    <div style={{flex: 4}}>{item.name}</div>
                    <span style={{flex: 2}}>{item.value}</span>
                    <Button type="primary" shape="circle" icon="edit" onClick={() => {
                        this.setState({visible: true, item: item, value: item.value})
                    }}/>
                </div>
            )
        }
        if ((/.*uid[0-9]?$/).test(item.key)) {
            return (
                <div key={i}
                     style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                    <div style={{flex: 4}}>{item.name}</div>
                    <span style={{flex: 2}}>{item.value == 0 ? '已关闭' : item.value}</span>
                    <Button type="primary" shape="circle" icon="edit" onClick={() => {
                        this.setState({visible: true, item: item, value: item.value})
                    }}/>
                </div>
            )
        }
        if ((/.*count[0-9]?$/).test(item.key)) {
            return (
                <div key={i}
                     style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                    <div style={{flex: 4}}>{item.name}</div>
                    <span style={{flex: 2}}>{item.value}</span>
                    <Button type="primary" shape="circle" icon="edit" onClick={() => {
                        item.min = 0;
                        this.setState({visible: true, item: item, value: item.value})
                    }}/>
                </div>
            )
        }

        if (item.group === 5 && (/.*rate$/).test(item.key)) {
            return (
                <div key={i}
                     style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                    <div style={{flex: 4}}>{item.name}</div>
                    <span
                        style={{flex: 2}}>{`${item.value / 1000}%`}</span>
                    <Button type="primary" shape="circle" icon="edit" onClick={() => {
                        this.setState({visible: true, item: item, value: item.value})
                    }}/>
                </div>
            )
        }
        if ((/.*web$/).test(item.key)) {
            return (
                <div key={i}
                     style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                    <div style={{flex: 4}}>{item.name}</div>
                    <span style={{flex: 2, paddingRight: 10}}>{item.value}</span>
                    <Button type="primary" shape="circle" icon="edit" onClick={() => {
                        this.setState({visible: true, item: item, value: item.value})
                    }}/>
                </div>
            )
        }
        return (
            <div key={i}
                 style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14, lineHeight: '32px'}}>
                <div style={{flex: 4}}>{item.name}</div>
                <span
                    style={{flex: 2}}>{(((/.*fee$/).test(item.key) || (/.*rate$/).test(item.key) || (/.*award$/).test(item.key))) ? `${item.value / 100}%` : item.value}</span>
                <Button type="primary" shape="circle" icon="edit" onClick={() => {
                    this.setState({visible: true, item: item, value: item.value})
                }}/>
            </div>
        )
    }

    updateConfig(group, key, value) {
        let url = '';
        switch (group) {
            case 4:
                url = '/api/guess/config/update'
                break;
            case 1:
            case 2:
            case 3:
            default:
                url = '/api/system/config/edit'
                break;
        }
        window.$http.postForm(url, {key: key, value: value}).then(res => {
            if (res.status === 10000) {
                window.$message.success('修改成功', 2);
                this.setState({visible: false})
                this._getList();
            } else {
                window.$message.error(res.message, 2);
            }
        }).catch(err => {
            window.$message.error('通讯失败', 2)
        })
    }

    update(symbol, direction, value) {
        window.$http.postForm('/api/config/system/edit', {
            key: 'auto_quantity_symbol_switch',
            symbol_name: symbol,
            value: value,
            direction: direction
        }).then(res => {
            if (res.status === 10000) {
                window.$message.success('修改成功', 2);
                this.setState({visible: false})
                this._getList();
            } else {
                window.$message.error(res.message, 2);
            }
        }).catch(err => {
            window.$message.error('通讯失败', 2)
        })
    }

    checkNumber(value, type) {
        if (type === 1) {
            let val = Precision.getPrecision(value, 4);
            if (val === 0 || val) {
                this.setState({
                    value: val,
                    disabled: false
                })
            } else {
                this.setState({
                    disabled: true
                })
            }

        } else if (type === 2) {
            let val = Precision.getPrecision(value, 2);
            if (val === 0 || val) {
                this.setState({
                    value: val,
                    disabled: false
                })
            } else {
                this.setState({
                    disabled: true
                })
            }
        } else if (type === 3) {
            let val = Precision.getPrecision(value, 3);
            if (val === 0 || val) {
                this.setState({
                    value: val,
                    disabled: false
                })
            } else {
                this.setState({
                    disabled: true
                })
            }
        }
    }

    _getSymbolName(item) {
        let temp = item.split('_');
        return `${temp[0]}/${temp[1]}${temp[2] == 'BUY' ? '买入' : '卖出'}`;
    }

    render() {
        return (
            <>
                <div  className={'config'} style={{padding: 30, columnCount: 4, columnGap: 30,}}>
                    {group.map((item, index) => {
                        if (index === 0) {
                            return null;
                        }

                        return (
                            <div
                                key={index}
                                style={{breakInside: 'avoid'}}
                            >
                                <Card
                                    title={item}
                                    bordered={false}
                                    loading={this.state.config.length < 1}
                                    style={{marginBottom: 30}}
                                    extra={
                                        <>
                                            {this.state.config.map((citem, i) => {
                                                if (Number(citem.group) !== Number(index)) {
                                                    return null;
                                                }
                                                if ((/.*switch$/).test(citem.key)) {
                                                    if (item.indexOf(citem.name.slice(0, citem.name.length - 2)) === -1) {
                                                        return null;
                                                    }
                                                    return (
                                                        <div key={i}
                                                             style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <div style={{marginRight: 5}}>开关</div>
                                                            <Switch onChange={(value) => {
                                                                this.updateConfig(citem.group, citem.key, value ? 1 : 2)
                                                            }} checkedChildren="启" unCheckedChildren="关"
                                                                    checked={citem.value == 1 ? true : false}/>
                                                        </div>

                                                    )
                                                }
                                                return null;
                                            })}
                                        </>
                                    }
                                >
                                    <div>
                                        {this.state.config.map((citem, i) => {
                                            if (Number(citem.group) !== Number(index)) {
                                                return null;
                                            }
                                            return this._renderType(item, citem, i);
                                        })}
                                    </div>
                                </Card>
                            </div>
                        )
                    })}
                </div>
                <Modal
                    title={'修改配置'}
                    visible={this.state.visible}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => {
                        this.setState({visible: false, value: undefined});
                    }}
                >
                    <div>
                        <Row style={{marginTop: 20}}>
                            <Col span={10} style={{lineHeight: '32px'}}>{this.state.item.name}：</Col>
                            <Col span={13} style={{lineHeight: '32px'}}>
                                {/*纯数字 输入 正常值*/}
                                {(this.state.item.key === 'open_min_minute' || this.state.item.key === 'open_max_minute')  &&
                                <InputNumber
                                    style={{width: '100%'}}
                                    defaultValue={this.state.item.value}
                                    // min={this.state.item.min ? this.state.item.min : -Infinity}
                                    // precision={6}
                                    // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // parser={value => value.replace(/\s?|(,*)/g, '')}
                                    onChange={(value) => {
                                        this.setState({
                                            value: value
                                        })
                                    }}

                                />
                                }

                                {/*百分比 输入*/}
                                {this.state.item.key && ((/.*fee$/).test(this.state.item.key) || (/.*rate$/).test(this.state.item.key) || (/.*award$/).test(this.state.item.key)) &&
                                <>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        defaultValue={this.state.item.group === 5 && (/.*rate$/).test(this.state.item.key) ? this.state.item.value / 1000 : this.state.item.value / 100}
                                        // precision={4}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={(value) => {
                                            if (this.state.item.group === 5 && (/.*rate$/).test(this.state.item.key)) {
                                                this.checkNumber(value, 3)
                                            } else {
                                                this.checkNumber(value, 2)
                                            }

                                        }}
                                    ><span>%</span></InputNumber>
                                </>
                                }
                                {this.state.item.key && (/.*time$/).test(this.state.item.key) &&
                                <>
                                    <TimePicker
                                        inputReadOnly={true}
                                        size={'small'}
                                        placeholder="时间"
                                        format='HH:mm:ss'
                                        defaultValue={moment('2019-01-19 ' + this.state.item.value)}
                                        onChange={(time, timeString) => {
                                            let val = this.state.value;
                                            val = timeString;
                                            this.setState({
                                                value: val
                                            })
                                        }}
                                    />
                                    {/*<TimePicker*/}
                                    {/*    inputReadOnly={true}*/}
                                    {/*    size={'small'}*/}
                                    {/*    defaultValue={moment('2019-01-19 ' + this.state.item.value.split('-')[1])}*/}
                                    {/*    placeholder="结束时间"*/}
                                    {/*    format='HH:mm:ss'*/}
                                    {/*    onChange={(time, timeString) => {*/}
                                    {/*        let val = this.state.value.split('-');*/}
                                    {/*        val[1] = timeString;*/}
                                    {/*        this.setState({*/}
                                    {/*            value: val.join('-')*/}
                                    {/*        })*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </>
                                }
                                {/*ticker*/}
                                {this.state.item.key && (/.*ticker/).test(this.state.item.key) &&
                                <>
                                    <TimePicker
                                        inputReadOnly={true}
                                        size={'small'}
                                        placeholder="选择时间"
                                        format='HH:mm:00'
                                        defaultValue={moment('2019-01-19 ' + this.state.item.value)}
                                        onChange={(time, timeString) => {
                                            this.setState({
                                                value: timeString
                                            })
                                        }}
                                    />
                                </>
                                }
                                {this.state.item.key && (/.*uid[0-9]?$/).test(this.state.item.key) &&
                                <>
                                    <Input
                                        style={{width: '100%'}}
                                        defaultValue={this.state.item.value}
                                        // precision={4}
                                        onChange={(e) => {
                                            this.setState({
                                                value: e.target.value
                                            })

                                        }}
                                    />
                                </>
                                }
                                {this.state.item.key && (/.*web$/).test(this.state.item.key) &&
                                <>
                                    <Input
                                        style={{width: '100%'}}
                                        defaultValue={this.state.item.value}
                                        // precision={4}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState({
                                                value
                                            })
                                        }}

                                    />
                                </>
                                }
                            </Col>
                        </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: 20}}>
                        <Button style={{marginLeft: 20}} onClick={() => {
                            this.setState({visible: false})
                        }}>返回</Button>
                        <Button type="primary" disabled={this.state.disabled} onClick={() => {
                            if ((/.*time$/).test(this.state.item.key)) {
                                let value = String(this.state.value).split('-');
                                if (moment('2019-01-22 ' + value[0]).isAfter(moment('2019-01-22 ' + value[1]))) {
                                    window.$message.warning('开始时间必须小于结束时间')
                                    return;
                                }
                            } else if ((/.*count[0-9]?$/).test(this.state.item.key)) {
                                let value = String(this.state.value).split('.');
                                if (value[1]) {
                                    window.$message.warning('不能存在小数');
                                    return;
                                }
                            }
                            this.updateConfig(this.state.item.group, this.state.item.key, this.state.value)
                        }}>提交</Button>
                    </div>
                </Modal>
            </>
        )
    }
}
