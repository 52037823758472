import React, {Component} from 'react';
import {Card, Input, Form, Select, Row, Col, Upload, Modal, Icon, Button} from 'antd';
import HttpUtils from "../../../utils/HttpUtils";
import './ChangeExamine.scss'

const {TextArea} = Input;

class CreateExamine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: -1,
            content: '',
            visible: false,
            categoryData: [],
            RoomList: []
        };
    }


    componentDidMount() {
        this.getCategoryList()
    }

    getCategoryList() {
        HttpUtils.postForm('/api/room/category/list', {
            pers: 100
        }).then(res => {
            this.setState({
                categoryData: res.data
            })
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    getRoomList(room_category_id) {
        HttpUtils.postForm('/api/room/list', {
            room_category_id
        }).then(res => {
            this.setState({
                RoomList: res.data
            })
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    handleCancel = () => {
        this.setState({visible: false})
        this.props.form.resetFields()
    }


    handleSubmit = (e) => {
        e.preventDefault();


        this.props.form.validateFields((err, values) => {
            const start_space_time = Number(Number(values.start_day * 60 * 24) + Number(values.start_hour * 60) + Number(values.start_minute)) * 60
            const end_space_time = Number(Number(values.end_day * 60 * 24) + Number(values.end_hour * 60) + Number(values.end_minute)) * 60

            if (start_space_time > end_space_time) {
                window.$message.error('结束时长不能小于开始时长')
                return
            }
            window.$http.postForm('/api/room/examine/distribution/update', {
                start_space_time,
                end_space_time,
                user_id: this.props.item.user_id,
                room_id: this.props.item.room_id,
            }).then(res => {
                if (res.status === 10000) {
                    window.$message.success('提交成功！');
                    this.props.refreshList();
                    this.setState({
                        visible: false
                    })
                } else {
                    window.$message.error(res.message);
                }
                this.props.form.resetFields()
            }).catch((err) => {
                window.$message.error('通讯失败');
            })
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <>
                <a onClick={() => {
                    this.setState({visible: true})
                    if (parseInt(this.props.item.start_space_time) > 0) {
                        var min = parseInt(this.props.item.start_space_time / 60);
                        if (min > 60) {
                            min = parseInt(this.props.item.start_space_time / 60) % 60;
                            var hour = parseInt(parseInt(this.props.item.start_space_time / 60) / 60);

                            if (hour > 24) {
                                hour = parseInt(parseInt(this.props.item.start_space_time / 60) / 60) % 24;
                                var day = parseInt(parseInt(parseInt(this.props.item.start_space_time / 60) / 60) / 24);
                            }else {
                                var day = 0
                            }



                        }else {
                            var day = 0
                            var hour = 0
                            var min = parseInt(this.props.item.start_space_time / 60) % 60;
                        }
                        this.props.form.setFieldsValue({
                            start_day: day,
                            start_hour: hour,
                            start_minute: min
                        })

                    }else {
                        this.props.form.setFieldsValue({
                            start_day: 0,
                            start_hour: 0,
                            start_minute: 0
                        })
                    }

                    if (parseInt(this.props.item.end_space_time) > 0) {
                        var min = parseInt(this.props.item.end_space_time / 60);
                        if (min > 60) {
                            min = parseInt(this.props.item.end_space_time / 60) % 60;
                            var hour = parseInt(parseInt(this.props.item.end_space_time / 60) / 60);

                            if (hour > 24) {
                                hour = parseInt(parseInt(this.props.item.end_space_time / 60) / 60) % 24;
                                var day = parseInt(parseInt(parseInt(this.props.item.end_space_time / 60) / 60) / 24);
                            }else {
                                var day = 0
                            }



                        }else {
                            var day = 0
                            var hour = 0
                            var min = parseInt(this.props.item.end_space_time / 60) % 60;
                        }
                        this.props.form.setFieldsValue({
                            end_day: day,
                            end_hour: hour,
                            end_minute: min
                        })

                    }else {
                        this.props.form.setFieldsValue({
                            end_day: 0,
                            end_hour: 0,
                            end_minute: 0
                        })
                    }
                }}>修改分配时长</a>
                <Modal
                    // width={window.innerWidth * 0.8}
                    style={{position: 'absolute', top: 30, left: 0, right: 0}}
                    title="修改分配时长"
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.handleCancel}
                    className='center'
                >
                    <Card>
                        <Form
                            className={'right4'}
                            onSubmit={this.handleSubmit}
                        >
                            <Row gutter={[32, 8]}>
                                <Form.Item label={'开始时长'} labelCol={{span: 6}} wrapperCol={{span: 18}}>
                                    <Col span={8}>
                                        {getFieldDecorator('start_day', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input addonAfter={'天'} autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('start_hour', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input addonAfter={'时'} autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('start_minute', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input addonAfter={'分'} autoComplete="off"/>
                                        )}
                                    </Col>
                                </Form.Item>
                            </Row>

                            <Row gutter={[32, 8]}>
                                <Form.Item label={'结束时长'} labelCol={{span: 6}} wrapperCol={{span: 18}}>
                                    <Col span={8}>
                                        {getFieldDecorator('end_day', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input addonAfter={'天'} autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('end_hour', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input addonAfter={'时'} autoComplete="off"/>
                                        )}
                                    </Col>
                                    <Col span={8}>
                                        {getFieldDecorator('end_minute', {
                                            rules: [{required: true, message: '必须填写'}]
                                        })(
                                            <Input addonAfter={'分'} autoComplete="off"/>
                                        )}
                                    </Col>
                                </Form.Item>
                            </Row>


                            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                <Button style={{marginLeft: 20}} onClick={this.handleCancel}>返回</Button>
                                <Button type="primary" htmlType="submit">提交</Button>
                            </div>
                        </Form>
                    </Card>
                </Modal>
            </>
        );
    }
}

const WrappedCreateExamine = Form.create()(CreateExamine);
export default WrappedCreateExamine;
