import React, {Component} from 'react';
import {Card, PageHeader, Button} from 'antd';
import CustomTable from "../../components/CustomTable";
import moment from 'moment'
import FilterForm from "../../components/Filter";
import ExamineThirdParty from "./components/ExamineThirdParty";
import AddAndBind from "../Room/components/AddAndBind";
import HttpUtils from "../../utils/HttpUtils";


const select = [
    {
        name: '创建时间',
        type: 'date',
        dataIndex: ['start_time', 'end_time']
    },
    {
        name: '人员编号/手机号/姓名',
        type: 'input',
        dataIndex: 'key',
        inputWidth:450
    },
    {
        name: '审核状态',
        type: 'select',
        option: [{id: 1, name: '绑定中'}, {id: 2, name: '绑定成功'}, {id: 3, name: '拒绝绑定'}],
        dataIndex: 'status'
    },
]

export default class ThirdPartyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            status_loading: false,
            form: {
                pers: 10,
                page: 1,
            },
            values: {},
            export_loading: false,
            visible: 1,
            value: '',
            status: 0,
            // visible: false,
            visible2:false,
            deptList:[]

        }

        this.columns = [
            {
                width: 200,
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => {
                    return moment(text).format('YYYY-MM-DD  HH:mm:ss')
                }
            },
            {
                title: '微信头像',
                dataIndex: 'user.avatar',
                key: 'user.avatar',
                width: 120,
                render: (text => (
                    <img src={text} style={{maxHeight: 60, maxWidth: 60}}/>
                ))
            },
            {
                title: '手机号',
                dataIndex: 'user.mobile_phone',
                key: 'user.mobile_phone',
                width: 120,
            },
            {
                title: '人员编号',
                dataIndex: 'user.pin',
                key: 'user.pin',
                width: 120,
            },
            {
                title: '真实姓名',
                dataIndex: 'user.name',
                key: 'user.name',
                width: 100,
            },
            // {
            //     title:'所属部门',
            //     dataIndex:'dept_id',
            //     key: 'dept_id',
            //     width: 100,
            //     render:text=>{
            //         if(text === 0){
            //             return '未分类'
            //         }else {
            //             // console.log('让我康康',)
            //             if (this.state.deptList.length > 0 && this.state.deptList.filter(item=>{return item.id  === text}).length > 0 ){
            //                 return this.state.deptList.filter(item=>{return item.id  === text})[0].name
            //             }
            //         }
            //     }
            // },
            {
                title: '微信昵称',
                dataIndex: 'user.nickname',
                key: 'user.nickname',
                width: 120,
            }, {
                title: '性别',
                dataIndex: 'user.gender',
                key: 'user.gender',
                width: 100,
                render: (text => {
                    switch (text) {
                        case '' :
                            return '未知'
                        case 'M' :
                            return '男'
                        case 'F' :
                            return '女'
                    }
                })
            },

            {
                title: '所在省份城市',
                dataIndex: 'user.province',
                key: 'user.province',
                width: 100,
                render: ((text, record) => text + ' ' + record.user.city)
            },
            {
                title: '审核状态',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text => {
                    switch (text) {
                        case 1 :
                            return '绑定中';
                        case 2 :
                            return '绑定成功';
                        case 3 :
                            return '拒绝绑定';
                    }
                })
            },
            {
                title: '拒绝绑定原因',
                dataIndex: 'reason',
                key: 'reason',
                width: 150,
                render: (text) => {
                    return <>
                        <p className={'wrap'}>{text}</p>
                    </>
                }
            },
            {
                title: '操作',
                width: 120,

                render: (text, record) => (
                    <>
                        {record.status === 1 ? <ExamineThirdParty ref={(e)=>{ this.examineBind = e}}  handleCancel={this.handleCancel} status={this.state.visible} item={record} id={record.id} refreshList={() => {
                                this.custom_table.getList();
                                this.setState({visible2: false})
                            }}/>

                            : null}
                    </>

                )
            }
        ]

    }





    componentDidMount() {
        // this.getList()
    }

    getList() {
        HttpUtils.postForm('/api/dept/list', {}).then(res => {
            if (res.status === 10000) {
                if (res.data.length > 0){
                    this.setState({
                        deptList:res.data
                    })
                }
            }
        })
    }

    handleCancel = () => {
        this.setState({
            visible1: false,
            visible2: false
        })
    }

    judgeThirdParty(id) {
        if (this.state.status != 0) {
            return
        }
        HttpUtils.postForm('/api/user/check/mobile', {
            id
        }).then(res => {
            if (res.status === 10000) {
                this.setState({
                    status: res.data
                })
            }
        }).catch(err => {
            console.log(err);
            window.$message.error('通讯失败')
        })
    }

    onSubmit = (values) => {
        this.custom_table.onSubmit(values);
    };
    onReset = () => {
        this.custom_table.onReset();
    };


    render() {
        return (
            <div>
                <Card style={{marginBottom: 15}}>
                    <PageHeader title="用户审核 " subTitle=""/>
                </Card>
                <FilterForm select={select} onSubmit={this.onSubmit} onReset={this.onReset}/>
                <Card bordered={false}>
                    <CustomTable
                        scroll={{x: 1600}}
                        url={'/api/user/bind/apply/list'}
                        columns={this.columns}
                        ref={(e) => {
                            this.custom_table = e
                        }}
                    />
                </Card>
            </div>
        );
    }
}



